import React, {  } from 'react'
import styled from 'styled-components/macro'

import { SideSheet, Pane, Paragraph, Text, Heading, Table } from 'evergreen-ui'

import { CardHeader, Button } from '@pearly/lib'

import * as Types from 'types'

import { auth } from 'firebase'

import moment from 'moment'
import axios from 'axios'

export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    invoice: any
    // paymentEventId?: string,
    // getAccountToken?: () => Promise<string>,
    // hostUrl: string,
    // tanentType?: string,
    // isAdmin?: boolean,
    // updatePracticeInvoice?: any
}

const ViewPracticeInvoice = ({
    isShown,
    setIsShown,
    invoice
    // paymentEventId,
    // getAccountToken,
    // hostUrl,
    // tanentType,
    // isAdmin = false,
    // updatePracticeInvoice
}: Props) => {

    const downloadMemberPDF = () => {
        console.log("URL",process.env.REACT_APP_URL_HOST + "download/invoice/provider");
        
        auth().currentUser?.getIdToken().then((token: string) => {

            axios({
                method: 'post',
                url: process.env.REACT_APP_URL_HOST + "download/invoice/provider", //'http://localhost:4000/download/receipt',
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` },
                data: invoice
            }).then((response) => {
                const file = new Blob([response.data], {
                    type: 'application/pdf',
                });

                const fileURL = URL.createObjectURL(file);

                window.open(fileURL);
            }, (error) => {
                console.log(error);
            });
        }).catch((err: any) => {
            console.log(err);
        })
    };
    return (
        <>
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={733}
                className="SideSheet"
                preventBodyScrolling={true}
                //@ts-ignore
                position={'right'}
                shouldCloseOnOverlayClick= {false}
            >

                <SheetLayout className='SheetLayout' >
                    <CardHeader gridArea="header">
                        <Heading size={600}>Print invoice for {invoice?.subscriberAccount?.name} </Heading>
                    </CardHeader>

                    <Pane gridArea="body" overflow="scroll" height='100%'>
                        <Pane margin={24}>
                            <Heading color={'#1070CA'} textTransform="uppercase" marginBottom={8}>
                                Invoice: {invoice?.subscriberAccount?.code}-{invoice?.invoiceSequence}
                            </Heading>
                            <Text marginTop={0} ><b>{invoice?.account?.name}</b></Text>
                            {invoice?.account?.offices?.length > 0 ?
                                <Paragraph marginBottom={24}>
                                    {invoice?.account?.offices[0].name}<br />
                                    {invoice?.account?.offices[0].address1}, {invoice?.account?.offices[0].address2}<br />
                                    {invoice?.account?.offices[0].city} {invoice?.account?.offices[0].state}
                                    {invoice?.account?.offices[0].zip}
                                    <br /> Tel: {invoice?.account?.offices[0].phone}
                                </Paragraph>
                                :
                                <></>
                            }
                            <Pane display="flex" width="100%" >
                                <Pane display="flex" flexDirection="column" width="50%">
                                    <Heading color={'#1070CA'} textTransform="uppercase" marginBottom={8}>
                                        Billing details
                                    </Heading>
                                    <Pane display="flex" flexDirection="column" marginBottom={24}>
                                        <Text marginBottom={4} overflowWarp="break-word" wordWrap="break-word" >
                                            <strong>Plan: {invoice?.subscription?.plan?.name}</strong>
                                        </Text>
                                        <Text marginBottom={4}>
                                            <strong>Period: </strong>
                                            {(invoice?.rangeStart && invoice?.rangeEnd) ? `${moment.utc(invoice?.rangeStart).format('M/D/YYYY')} to ${moment.utc(invoice?.rangeEnd).format('M/D/YYYY')}`: 'N/A'}
                                        </Text>
                                        <Text marginTop={0}>
                                            <strong>Bill to: </strong>
                                            {invoice?.subscriberAccount?.name}
                                        </Text>
                                        {invoice?.account?.offices?.length > 0 ?
                                            <Text marginTop={0}>
                                                <strong>Email: </strong>
                                                {invoice?.account?.offices[0]?.email}
                                            </Text>
                                            : <></>
                                        }
                                        <Text marginBottom={4}>
                                            <strong>Invoice Number: </strong>
                                            <Text textTransform="uppercase" >{invoice?.subscriberAccount?.code}-{invoice?.invoiceSequence}</Text>
                                        </Text>
                                        <Text marginBottom={4}>
                                            <strong>Invoice Date: </strong>
                                            {invoice?.invoiceDate ? moment.utc(invoice?.invoiceDate).format('M/D/YYYY') : ''}
                                        </Text>
                                    </Pane>
                                </Pane>
                            </Pane>
                            <Pane display="flex" alignItems="center" width="100%"
                                justifyContent="center"
                                border="default"
                            >

                                <Table width="100%">
                                    <Table.Body>
                                        <Table.Body>
                                            <Table.Head>
                                                <Table.TextHeaderCell flexBasis={200} >
                                                    <p className='text-left' >Fee Type</p>
                                                </Table.TextHeaderCell>
                                                <Table.TextHeaderCell>
                                                    <p className='text-right' >{`Fee's(USD)`}</p>
                                                </Table.TextHeaderCell>
                                                <Table.TextHeaderCell>
                                                    <p className='text-right'  >Quantity</p>
                                                </Table.TextHeaderCell>
                                                <Table.TextHeaderCell >
                                                    <p className='text-right' >{`Amount(USD)`}</p>
                                                </Table.TextHeaderCell>
                                            </Table.Head>
                                            <Table.Body>
                                                <Table.Row >
                                                    <Table.TextCell flexBasis={200}>
                                                        {invoice?.type === Types.paymentType.SUBS ?
                                                            'Subscription for ' + invoice?.subscription?.plan?.name : 'Other'
                                                        }
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <p className='text-right' >{invoice?.amount?.toFixed(2)}</p>
                                                    </Table.TextCell>
                                                    <Table.TextCell marginRight={13}  >
                                                        <p className='text-right'>1</p>
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <p className='text-right'>{invoice?.amount?.toFixed(2)}</p>
                                                    </Table.TextCell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.TextCell flexBasis={200}></Table.TextCell>
                                                    <Table.TextCell marginRight={11} className="text-right">
                                                        <b className="text-right" >Sub Total</b>
                                                    </Table.TextCell>
                                                    <Table.TextCell></Table.TextCell>
                                                    <Table.TextCell>
                                                        <p className='text-right' ><b className="text-right">
                                                            {invoice?.amount?.toFixed(2)} </b></p>
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table.Body>
                                    </Table.Body>
                                </Table>
                            </Pane>

                            <Pane display="flex" marginTop="2%" alignItems="center" width="100%" >
                                <Pane display="flex" flexDirection="column" width="50%">
                                    {/* {invoice?.status === Types.invoiceStatus.PAID && <Table width="100%">
                                        <Table.Body>
                                            <Table.Body>
                                                <Table.Row style={{ border: 'none' }}>

                                                </Table.Row>


                                                <Table.Row style={{ border: 'none' }}>
                                                </Table.Row>

                                                <Table.Row>
                                                </Table.Row>

                                                <Table.Row>
                                                    <Table.TextCell >
                                                        <b>Paid on</b>
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <p className='text-right'>{invoice?.paymentDate ? moment.utc(invoice?.paymentDate).format('M/D/YYYY') : ''}</p>
                                                    </Table.TextCell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table.Body>
                                    </Table>} */}
                                </Pane>
                                <Pane display="flex" flexDirection="column" width="50%">
                                    <Table width="100%">
                                        <Table.Body>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.TextCell >
                                                        Tax Rate
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <p className='text-right'>0%</p>
                                                    </Table.TextCell>

                                                </Table.Row>


                                                <Table.Row>
                                                    <Table.TextCell >
                                                        Tax Amount
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <p className='text-right'>0.00 </p>
                                                    </Table.TextCell>

                                                </Table.Row>

                                                <Table.Row>
                                                    <Table.TextCell >
                                                        <b>Total</b>
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <b><p className='text-right'>
                                                            {invoice?.amount?.toFixed(2)} </p></b>
                                                    </Table.TextCell>

                                                </Table.Row>

                                                {/* <Table.Row>
                                                    <Table.TextCell >
                                                        <b>Total Amount Due</b>
                                                    </Table.TextCell>
                                                    <Table.TextCell>
                                                        <b><p className='text-right'>
                                                            0.00 USD</p></b>
                                                    </Table.TextCell>
                                                </Table.Row> */}
                                            </Table.Body>
                                        </Table.Body>
                                    </Table>
                                </Pane>
                            </Pane>
                        </Pane>
                        <Pane
                            gridArea="footer"
                            elevation={0}
                            padding={16}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text>For subscriptions, your configured payment method will be automatically charged monthly or annually based on the price at the time of billing until you cancel the subscription. If you have a discount, it will be applied to the price at the time of billing until it expires. To cancel your subscription at any time, go to Service Agreement and Cancel Subscription.
                                For one-time service, your configured payment method will reflect the same amount as shown in the invoice. Terms and conditions, pricing, feature services, and support options are subject to change without notice.
                                All the dates and times are in Pacific Standard Time (PST).</Text>
                        </Pane>
                        <Pane
                            gridArea="footer"
                            elevation={0}
                            padding={16}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Pane display="flex" width="100%" >

                                <Button
                                    className="no-print"
                                    type="submit"
                                    appearance="primary"
                                    marginLeft={10}
                                    height={48}
                                    onClick={() => window.print()}
                                >
                                    Print
                                </Button>
                                <Button
                                    className="button-danger"
                                    type="button"
                                    height={48}
                                    marginLeft={10}
                                    onClick={() =>
                                        downloadMemberPDF()
                                    }
                                >
                                    Download PDF
                                </Button>
                            </Pane>


                        </Pane>
                    </Pane>
                </SheetLayout>

            </SideSheet>
        </>
    )
}


export default ViewPracticeInvoice

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
  
`