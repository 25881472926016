import axios from 'axios'
import { toaster } from 'evergreen-ui'

import { auth } from 'firebase/app'

export const signupWithEmailAndPassword = async (
  setSignupLoading: React.Dispatch<React.SetStateAction<boolean>>,
  email: string,
  password: string,
  cb: (idToken: string) => void
) => {
  try {
    setSignupLoading(true)
    const { user } = await auth().createUserWithEmailAndPassword(email, password)
    if (!user) throw Error('Unable to create user')

    const idToken = await user.getIdToken()
    cb(idToken)
  } catch (err) {
    setSignupLoading(false)
    toaster.notify(err.message)
  }
}

export const loginWithEmailAndPassword = async (
  setLoginLoading: React.Dispatch<React.SetStateAction<boolean>>,
  email: string,
  password: string
) => {
  try {
    setLoginLoading(true)
    await auth().signInWithEmailAndPassword(email, password)
  } catch (err) {
    setLoginLoading(false)
    toaster.notify(err.message)
  }
}

export const loginWithInvitation = async (email: string, password: string, cb: () => void) => {
  try {
    await auth().signInWithEmailLink(email, window.location.href)
    const currentUser = auth().currentUser
    if (currentUser) {
      currentUser.updatePassword(password)
      cb()
    } else {
      throw Error('User not found')
    }
  } catch (err) {
    toaster.notify(err.message)
  }
}

export const logout = async () => {
  try {
    await auth().signOut()
  } catch (err) {
    toaster.notify(err.message)
  }
}

export const resetPassword = async (email: string, success: () => void, failure: () => void) => {
  try {
    await auth().sendPasswordResetEmail(email)
    toaster.success('Password Reset Email Sent')
    success()
  } catch (err) {
    toaster.danger(err.message)
    failure()
  }
}

export const resetProviderPassword = async (email: string, success: () => void, failure: () => void) => {
  try {

    axios({
      method: 'post',
      url: process.env.REACT_APP_URL_HOST + "sendProviderResetPasswordEmail", //'http://localhost:4000/sendResetPasswordEmail',
      data: { email: email }
    }).then((res) => {
      if (res.data?.massage) {
        toaster.danger(res.data?.massage)
        failure()
      } else {
        toaster.success('Password Reset Email Sent')
        success()
      }
    })
  } catch (err) {
    toaster.danger(err.message)
    failure()
  }
}

export const verifyProviderPasswordResetCode = async (code: string, success: (user: string) => void, failure: () => void) => {
  try {
    const user = await auth().verifyPasswordResetCode(code)
    success(user)
  } catch (err) {
    toaster.danger(err.message)
    failure()
  }
}

export const resetedPassword = async (code: string, password: string, success: () => void, failure: () => void) => {
  try {
    await auth().confirmPasswordReset(code, password)
    toaster.success('Your Password has been Reset. Please login to continue')
    success()
  } catch (err) {
    toaster.danger(err.message)
    failure()
  }
}