import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useApolloClient } from '@apollo/react-hooks'

import { SideSheet, Pane, Heading, toaster, Label } from 'evergreen-ui'
import Button from '../button'
import ServicePlanList from '../service-plan-list'
import { Formik, Form } from 'formik';
import * as Yup from 'yup'
import TextInputField from '../text-input-field'
import moment from 'moment'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  enrollServicePlan: (variables: any) => void
  isLoading: boolean
}

type PlanInfo = {
  id: string
  name: string
  annualPrice: number | null
  monthlyPrice: number | null
  setupFee: number | null
}


const ServiceEnrollSheet = ({
  isShown,
  setIsShown,
  enrollServicePlan,
  isLoading
}: Props) => {
  const apolloClient = useApolloClient()
  const [selectedPlan, setSelectedPlan] = useState<PlanInfo>()

  const selectPlan = (
    plan: PlanInfo,
    setFieldValue: any
  ) => {
    setSelectedPlan(plan)
    setFieldValue('serviceFee', plan.monthlyPrice ?? 0.00)
    setFieldValue('setupFee', plan.setupFee ?? 0.00)
  }

  const enroll = async (firstBilling: Date, setupFee: number, serviceFee: number) => {
    if (!selectedPlan?.id) {
      toaster.danger("Must select a plan first.")
      return;
    }
    enrollServicePlan({
      planId: selectedPlan?.id,
      setupFee: setupFee,
      serviceFee: serviceFee,
      firstBilling: firstBilling
    })
    setIsShown(false)
  }

  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)

  console.log('selectedPlan', selectedPlan)

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={400}
      preventBodyScrolling={true}
      //@ts-ignore
      position={'right'}
      shouldCloseOnOverlayClick= {false}
    >
      <Formik initialValues={{
        setupFee: selectedPlan?.setupFee ?? 0.00,
        serviceFee: selectedPlan?.monthlyPrice ?? 0.00,
        startDateYear: nextMonth.getFullYear(),
        startDateMonth: nextMonth.getMonth() + 1,
        startDateDay: nextMonth.getDate()
      }}
        onSubmit={({ setupFee, serviceFee, startDateYear, startDateMonth, startDateDay }) => {
          const firstBilling = moment.utc(startDateYear + '/' + startDateMonth + '/' + startDateDay).toDate()
          enroll(firstBilling, setupFee, serviceFee)
        }}
        validationSchema={Yup.object({
          setupFee: Yup.string().required('Setup fee is required'),
          serviceFee: Yup.string().required('Service fee is required'),
          startDateMonth: Yup.string().required('Start Date Month is required'),
          startDateDay: Yup.string().required('start Date Day is required'),
          startDateYear: Yup.string().required('Start Date Year is required'),
        })}
      >
        {({ setFieldValue }) => (

          <Form style={{ height: '100%', width: '100%' }} autoComplete="off" >

            <SheetLayout>
              <Pane
                gridArea="body"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                background="blueTint"
                paddingTop={0}
              >
                <Heading size={500} flexShrink={0} textAlign="center" marginBottom={8}>
                  Select Plan
                </Heading>
                <ServicePlanList
                  selectPlan={ev => selectPlan(ev, setFieldValue)}
                  selectedPlanId={selectedPlan?.id}
                  apolloClient={apolloClient}
                  width="100%"
                />
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="setupFee" disabled={false} label="Setup Fee" type="number" width="100%" height={40} required />
                </Pane>
                <Pane display="flex" marginTop={0} marginX={12} >
                  <TextInputField name="serviceFee" disabled={false} label="Service Fee" type="number" width="100%" height={40} required />
                </Pane>
                <Label alignSelf="flex-start">Billing Start Date</Label>
                <Pane display="flex">
                  <TextInputField
                    name="startDateMonth"
                    flex={1}
                    placeholder="MM"
                    height={40}
                    marginRight={16}
                    textAlign="center"
                    hint="Month"
                    required
                  />
                  <TextInputField
                    name="startDateDay"
                    flex={1}
                    placeholder="DD"
                    height={40}
                    marginRight={16}
                    textAlign="center"
                    hint="Day"
                    required
                  />
                  <TextInputField name="startDateYear" flex={2} placeholder="YYYY" textAlign="center" height={40}
                    hint="Year" required
                  />
                </Pane>

              </Pane>
              <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="space-between">
                <Button
                  disabled={!(selectedPlan?.id)}
                  type="submit"
                  appearance="primary"
                  height={48}
                  justifyContent="center"
                  iconAfter={['far', 'chevron-right']}
                  isLoading={isLoading}
                >
                  Enroll
                </Button>
              </Pane>
            </SheetLayout>
          </Form>
        )}

      </Formik>
    </SideSheet>
  )
}

export default ServiceEnrollSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr;
`