import React from 'react'
import * as Types from '../types'
import moment from 'moment'

import styled from 'styled-components/macro'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Card, Pane, Heading } from 'evergreen-ui'
import Button from '../button'
import CardHeader from '../card-header'
import FormError from '../form-error'
import MemberDetailsFields from '../_fields/member-details-fields'

export type Props = {
  enrollmentState: Types.EnrollmentState
  updateEnrollmentState: (updatedState: Types.EnrollmentState) => void
  prev: () => void
  next: () => void
  parentId?: string
}

const EnrollMemberDetails = ({ enrollmentState, updateEnrollmentState, prev, next, parentId }: Props) => {
  const detailsState = enrollmentState.details

  return (
    <Formik
      initialValues={{
        firstName: detailsState?.firstName ?? '',
        lastName: detailsState?.lastName ?? '',
        phone: detailsState?.phone ?? '',
        dobMonth: detailsState?.dobMonth ?? (('' as unknown) as number),
        dobDay: detailsState?.dobDay ?? (('' as unknown) as number),
        dobYear: detailsState?.dobYear ?? (('' as unknown) as number),
        perio: detailsState?.perio ?? false
      }}
      onSubmit={fields => {
        updateEnrollmentState({ details: fields, enforce_credit: false })
        next()
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        phone: parentId ?
          Yup.string()
            .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number') :
          Yup.string()
            .required('Phone is required')
            .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
        dobMonth: Yup.number()
          .required('Birth month is required')
          .integer('Please provide a valid birth month')
          .min(1, 'Please provide a valid birth month')
          .max(12, 'Please provide a valid birth month'),
        dobDay: Yup.number()
          .required('Birth day is required')
          .integer('Please provide a valid birth day')
          .min(1, 'Please provide a valid birth day')
          .max(32, 'Please provide a valid birth day'),
        dobYear: Yup.number()
          .required('Birth year is required')
          .integer('Please provide a valid birth year')
          .min(1900, 'Please provide a valid birth year')
          .max(moment().year(), 'Please provide a valid birth year'),
        perio: Yup.boolean()
      })}
    >
      <Form style={{ height: '100%', width: '100%' }}>
        <SheetBodyLayout>
          <Pane gridArea="body" background="blueTint" overflow="scroll">
            <Card backgroundColor="white" elevation={0} margin={16} marginTop={4}>
              <CardHeader>
                <Heading size={500} flex={1} textAlign="center">
                  Complete {parentId ? 'Dependent' : 'Member'} Details
                </Heading>
              </CardHeader>
              <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
                <MemberDetailsFields provider />
                <FormError />
              </Pane>
            </Card>
          </Pane>
          <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="space-between">
            <Button
              appearance="minimal"
              height={48}
              justifyContent="center"
              iconBefore={['far', 'chevron-left']}
              onClick={prev}
            >
              Back
            </Button>
            <Button
              type="submit"
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
            >
              Next
            </Button>
          </Pane>
        </SheetBodyLayout>
      </Form>
    </Formik>
  )
}

export default EnrollMemberDetails

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'body'
    'footer';
  grid-template-rows: 1fr auto;
`
