import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { SideSheet, Pane, Avatar, Text, Heading, Button, Card, toaster } from 'evergreen-ui'
import CardHeader from '../card-header'
import { CANCEL_SERVICE_PAYMENT_EVENT, GET_SERVICE_RECIPT_BY_ID, TRY_NEW_SERVICE_PAYMENT } from './../graphql/_receipt'
import ConfirmDialogPayment from '../_dialogs/confirm-dialog'
import ConfirmDialogSolved from '../_dialogs/confirm-dialog'
import * as Types from './../types'
import { Spinner } from '..'
export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    charge?: any,
    hostUrl: string,
    tanentType?: string
}

const FailedServices = ({
    isShown,
    setIsShown,
    charge

}: Props) => {

    let secure: any = "";
    // let receiptData: any = {};
    const [receiptData, setReceiptData] = useState<Types.ServiceReceiptByIdQuery_serviceReceipt | null>()
    const [isConfirmDialogPaymentShown, setIsConfirmDialogPaymentShown] = useState(false)

    const [isConfirmDialogSolvedShown, setIsConfirmDialogSolvedShown] = useState(false)


    const { data: serviceReciptData, loading } = useQuery<Types.ServiceReceiptByIdQuery, Types.ServiceReceiptByIdQueryVariables>(GET_SERVICE_RECIPT_BY_ID, {
        context: { secure: true },
        variables: {
            paymentEventId: charge ? charge.id : ''
        },
        skip: !charge.id,
    });

    useEffect(() => {
        if (serviceReciptData) {
            setReceiptData(serviceReciptData.serviceReceipt)
        }
    }, [serviceReciptData])

    // receiptData = serviceReciptData?.serviceReceipt;

    secure = {
        firstName: receiptData?.account?.name,
        lastName: ""
    };

    // console.log(receiptData);

    // TRY_NEW_SERVICE_PAYMENT
    const [tryNewPaymentEvent, tryNewPaymentEventUpdate] = useMutation<Types.tryNewServicePayment, Types.tryNewServicePaymentVariables>(TRY_NEW_SERVICE_PAYMENT, {
        refetchQueries: ['PaymentsTable', 'PaymentsAnalytics']
    })

    useEffect(() => {
        if(tryNewPaymentEventUpdate.error?.message) {
            toaster.danger(tryNewPaymentEventUpdate.error?.message)
        } else if (tryNewPaymentEventUpdate?.data?.tryNewServicePayment?.paymentStatus === 'FAILED') {
            toaster.danger('Unable to proccess the payment.')
        } else if (tryNewPaymentEventUpdate?.data?.tryNewServicePayment?.paymentStatus === 'COMPLETE' && !tryNewPaymentEventUpdate.loading) {
            toaster.success(`Payment successfully complete!`)
            setIsShown(false)
        }
    }, [tryNewPaymentEventUpdate
        // , showPaymentsProblemsSheet
    ])

    const tryNewPaymentUpdating = () => {
        if (receiptData && charge) {
            tryNewPaymentEvent({ variables: { id: receiptData?.subscriptionID ?? '', paymentId: charge.id } })
        }
    }
    const tryNewPaymentFunction = () => {
        tryNewPaymentUpdating()
    }
    // # TRY_NEW_SERVICE_PAYMENT


    // CANCEL_SERVICE_PAYMENT_EVENT
    const [cancelServicePaymentEvent, cancelServicePaymentEventUpdate] = useMutation<Types.CancelServicePaymentEvent,
        Types.CancelServicePaymentEventVariables>(CANCEL_SERVICE_PAYMENT_EVENT, {
            refetchQueries: ['PaymentsTable']
        })

    useEffect(() => {
        if (cancelServicePaymentEventUpdate.error) toaster.danger('Unable to update the member payment problem')
        else if (cancelServicePaymentEventUpdate.data && !cancelServicePaymentEventUpdate.loading) {
            toaster.success(`Payment problem updated!`)
        }
    }, [cancelServicePaymentEventUpdate])

    const startUpdatingProblemSolved = () => {
        cancelServicePaymentEvent({
            variables: {
                Id: charge.id
            }
        })
    }

    const confirmSolvedFunction = () => {
        startUpdatingProblemSolved();
    }
    // # CANCEL_SERVICE_PAYMENT_EVENT

    var paymentMsg = "Are you sure you want to try the payment again?";

    return (
        <>
            <ConfirmDialogPayment
                isShown={isConfirmDialogPaymentShown}
                setIsShown={setIsConfirmDialogPaymentShown}
                confirm={() => tryNewPaymentFunction()}
                body={paymentMsg}
                intent="danger"
            />
            <ConfirmDialogSolved
                isShown={isConfirmDialogSolvedShown}
                setIsShown={setIsConfirmDialogSolvedShown}
                confirm={() => confirmSolvedFunction()}
                body="Are you sure you want to cancel this payment? Once you confirm the payment will be cancelled."
                intent="danger"
            />
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={500}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
                shouldCloseOnOverlayClick= {false}
            >
                {/* {console.log(1, charge)} */}
                {
                    loading ? (
                        <Pane height={84} paddingY={24}>
                            <Spinner delay={0} />
                        </Pane>
                    ) : (
                        <SheetLayout>
                            <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
                                <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
                                    <Pane display="flex" alignItems="center">
                                        <Avatar name={`${secure?.firstName}`} size={40} />
                                        <Pane marginLeft={16}>
                                            <Heading size={600}>
                                                {secure?.firstName}
                                            </Heading>
                                            <Text size={400}>Payment Problem Overview</Text>
                                        </Pane>
                                    </Pane>
                                </Pane>
                            </CardHeader>
                            <Pane gridArea="body" overflow="hidden">
                                <Pane gridArea="body" background="blueTint" overflow="scroll">
                                    <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                                        <CardHeader>
                                            <Heading size={500}>Payment Issues</Heading>
                                        </CardHeader>
                                        <Pane padding={16}>
                                            <Pane padding={8} display="flex" flexDirection="column">
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Date: </strong>{charge.paymentDate ?? ''}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Last Four Digits: </strong>
                                                    {charge.last4}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Exp. Date: </strong>
                                                    {charge.cardExp ?? ''}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Type: </strong>
                                                    {receiptData?.type}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Amout: </strong>
                                                    ${charge.amount}
                                                </Text>
                                                <Text size={500} marginBottom={8}>
                                                    <strong>Error: </strong>
                                                    {charge.paymentErrorMessage ?? 'Unknown'}
                                                </Text>
                                            </Pane>
                                            <Pane display="flex" justifyContent="flex-start">
                                                <Button marginRight={8}
                                                    intent="danger"
                                                    onClick={() => {
                                                        setIsConfirmDialogPaymentShown(true)
                                                    }
                                                    }
                                                >
                                                    Try new payment
                                                </Button>
                                                {/*
                                        <Button marginRight={8}
                                            intent="success"
                                            onClick={() => {
                                                setIsConfirmDialogSolvedShown(true)
                                            }
                                            }
                                        >
                                            Cancel Payment
                                        </Button> */}
                                            </Pane>
                                        </Pane>
                                    </Card>
                                </Pane>
                            </Pane>




                        </SheetLayout>
                    )
                }

            </SideSheet>
        </>
    )
}


export default FailedServices

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
  
`

