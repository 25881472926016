import React, {useEffect} from 'react'

import { Pane, toaster, Text } from 'evergreen-ui'
import * as Types from './types'


import {
  clearentPayButton,
  clearentAddPayButtonListener,
  clearentRemovePayButtonListener,
  saveClearentCard,
  setClearentPayButtonArea,
  changePayButtonText
} from './utility/clearent-pay-button'

export type Props = {
  id: string
  paymentKey?: string
  creditCardToken?: Types.SavedTokenInput
  callBack: (token: Types.SavedTokenInput) => any
}


const PayButton = ({id, callBack, paymentKey, creditCardToken }: Props) => {

  const createButton = (divId: string, paymentKey: string) => {
    clearentAddPayButtonListener((success, responseJson) => {
      const { clearentCardType, clearentTokenId, expDate, lastFour } = saveClearentCard(responseJson);
      if(success) {
        callBack({
          cardType: clearentCardType,
          expDate: expDate,
          lastFourDigits: lastFour,
          tokenId: clearentTokenId,
          //status: responseJson
        })
        const payButtonText = setClearentPayButtonArea(clearentCardType, lastFour, divId );
        changePayButtonText(payButtonText)
      } else {
        // callBack({
        //   cardType: clearentCardType,
        //   expDate: expDate,
        //   lastFourDigits: lastFour,
        //   tokenId: clearentTokenId,
        //   status: responseJson
        // })
        toaster.danger(`${responseJson}`);
      }      
    })
    const cardType = creditCardToken?.cardType??''
    const lastFour = creditCardToken?.lastFourDigits??''
    const payButtonText = setClearentPayButtonArea(cardType, lastFour, divId);
  
    clearentPayButton(paymentKey, divId, payButtonText);
  }

  const buttonInit =  (divId: string, paymentKey: string) => {
    // @ts-ignore
    if(window.Clearent && window.AddClearentListener)
      createButton(divId, paymentKey)
    else {
      setTimeout(() => 
      {
        buttonInit(divId, paymentKey)
      },
      1000);
    }
  }

  const divId = `${id}-divMemberPay`
  useEffect(()=> {
    if(paymentKey!=null && paymentKey!= '') {
      buttonInit(divId, paymentKey)
    }
    return () => {
      clearentRemovePayButtonListener()
    }
   }, [])

  return (
    paymentKey!=null && paymentKey!= ''? (
      <Pane padding={16} display="flex" flexDirection="column">
          <div id={divId}></div>
      </Pane>
    ):
    (
      <Text>
      Not set up to accept payment.
      </Text>
    )
  )
}

export default PayButton
