import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { ApolloClient } from 'apollo-client'
import { useQuery } from '@apollo/react-hooks'
import { GET_PLAN_CARD_LIST } from './graphql/_plan-card-list'
import * as Types from './types'

import { Switch, Pane, Badge, Heading, UnorderedList, ListItem, PaneProps, Text } from 'evergreen-ui'

import Button from './button'
import Card from './card'
import Spinner from './spinner'
import Link from './link'
import { colors } from './utility/theme'
import { ageRangeToString } from './utility/formatters'
import { getShownPrice } from './utility/transforms'
import { useMediaQueryContext } from './media-query-provider'

import ContractSheet from './_sheets/contract-sheet'
import { PlanTerm } from './types'

export interface Props extends PaneProps {
  apolloClient: ApolloClient<any>
  accountId?: string
  age?: number
  perio?: boolean
  selectPlan?: (plan: Types.PlanCardList_livePlans, term: Types.PlanTerm) => void
  unSelectPlan?: () => void
  selectedPlanId?: string
  currentPlanTerm?: PlanTerm | null
  isSite?: boolean
  siteColor?: Types.SiteColor
  dependent?: boolean
}

const PlanCardList = ({
  accountId,
  age,
  perio,
  apolloClient,
  selectedPlanId,
  currentPlanTerm,
  selectPlan,
  unSelectPlan, 
  isSite,
  siteColor,
  dependent,
  ...props
}: Props) => {
  const [isContractSheetShown, setIsContractSheetShown] = useState(false)
  const [planId, setPlanId] = useState('')

  // refetch every time
  const { loading, error, data } = useQuery<Types.PlanCardList, Types.PlanCardListVariables>(GET_PLAN_CARD_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      age,
      perio
    },
    client: apolloClient
  })

  const annualPlans = data?.livePlans
    .filter(plan => plan.annualPriceActive)
    .sort((a, b) => a.annualPrice! - b.annualPrice!)
  const monthlyPlans = data?.livePlans
    .filter(plan => plan.monthlyPriceActive)
    .sort((a, b) => a.monthlyPrice! - b.monthlyPrice!)

  const hasAnnualAndMonthly = !!(annualPlans?.length && monthlyPlans?.length)
  const [term, setTerm] = currentPlanTerm?useState<Types.PlanTerm>(currentPlanTerm):useState<Types.PlanTerm>()

  const getInitialTerm = () => {
    if(!hasAnnualAndMonthly) {
      if(annualPlans?.length) {
        return Types.PlanTerm.ANNUAL
      } else {
        return Types.PlanTerm.MONTHLY
      }
    } else {
      return currentPlanTerm
    }
  }

  useEffect(() => {
    const initialTerm: any = getInitialTerm()
    setTerm(initialTerm ?? undefined)
  }, [data])

  const shownPlans = hasAnnualAndMonthly
    ? term === Types.PlanTerm.ANNUAL
      ? annualPlans
      : monthlyPlans
    : data?.livePlans

  const changeTerm = (newPlanTerm: Types.PlanTerm) => {
    setTerm(newPlanTerm)
    var foundPlan = null
    if(newPlanTerm == Types.PlanTerm.ANNUAL) {
      foundPlan = annualPlans?.find(p=>p.id == selectedPlanId)
    }
    else if(newPlanTerm == Types.PlanTerm.MONTHLY) {
      foundPlan = monthlyPlans?.find(p=>p.id == selectedPlanId)
    }
    if(foundPlan && newPlanTerm)
      selectPlan && selectPlan(foundPlan, newPlanTerm)
    else
      unSelectPlan && unSelectPlan()
  }

  // Effect runs once plans are loaded, sets initial term based on presence of annualPlans
  useEffect(() => {
    // Redeclare annual plans to avoid dependency warning
    if(!currentPlanTerm) {
      const annualPlans = data?.livePlans
        .filter(plan => plan.annualPriceActive)
        .sort((a, b) => a.annualPrice! - b.annualPrice!)

      if (annualPlans) {
        setTerm(annualPlans.length ? Types.PlanTerm.ANNUAL : Types.PlanTerm.MONTHLY)
      }
    }
  }, [data])

  return loading || error || !data || !term ? (
    <Pane height={225} display="flex" alignItems="center">
      <Spinner />
    </Pane>
  ) : (
    <>
      <ContractSheet
        isShown={isContractSheetShown}
        setIsShown={setIsContractSheetShown}
        planId={planId}
        apolloClient={apolloClient}
        dependent={dependent}
      />

      <Pane overflow="scroll" padding={16} {...props}>
       {hasAnnualAndMonthly && (
          <Pane display="flex" justifyContent="center" alignItems="center" marginBottom={24}>
            <Heading size={600}>Annual</Heading>
            <Switch
              checked={term === Types.PlanTerm.ANNUAL}
              onChange={() => changeTerm(term === Types.PlanTerm.ANNUAL ? Types.PlanTerm.MONTHLY : Types.PlanTerm.ANNUAL)}
              hasCheckIcon={false}
              marginX={16}
              height={24}
              transform="rotate(180deg)"
            />
            <Heading size={600}>Monthly</Heading>
          </Pane>
        )} 
        {!shownPlans?.length ? (
          <Heading size={500} flexShrink={0} textAlign="center" paddingY={16} paddingX={40}>
            There are no qualifying live plans in this program.
          </Heading>
        ) : (
          <Pane
            display="flex"
            flexDirection={isSite ? 'row' : 'column'}
            justifyContent="center"
            flexWrap={isSite ? 'wrap' : 'nowrap'}
          >
            {shownPlans.map((plan, i) => {
              return (
                <PlanCard
                  key={i}
                  plan={plan}
                  term={term}
                  margin={isSite ? 12 : 0}
                  marginBottom={isSite ? 'auto' : 8}
                  onClick={() => selectPlan && selectPlan(plan, term)}
                  showContractSheet={() => {
                    setPlanId(plan.id)
                    setIsContractSheetShown(true)
                  }}
                  borderColor={plan.id === selectedPlanId? colors.blue.base : 'transparent'}
                  borderStyle="solid"
                  borderWidth="2px"
                  isSite={isSite}
                  siteColor={siteColor}
                  dependent={dependent}
                />
              )
            })}
          </Pane>
        )}
      </Pane>
    </>
  )
}

export default PlanCardList

interface PlanCardProps extends PaneProps {
  plan: Types.PlanCardList_livePlans
  term: Types.PlanTerm
  showContractSheet: () => void
  isSite?: boolean
  siteColor?: Types.SiteColor
  dependent?: boolean
}

// const colorMap = {
//   [Types.SiteColor.BLUE]: { buttonIntent: 'none' as const, badgeColor: 'blue' as const },
//   [Types.SiteColor.GREEN]: { buttonIntent: 'success' as const, badgeColor: 'green' as const },
//   [Types.SiteColor.ORANGE]: { buttonIntent: 'warning' as const, badgeColor: 'orange' as const },
//   [Types.SiteColor.RED]: { buttonIntent: 'danger' as const, badgeColor: 'red' as const }
// }

const PlanCard = ({ plan, term, showContractSheet, isSite, siteColor, dependent = false, ...props }: PlanCardProps) => {
  const { providerParam } = useParams<{ providerParam: string }>()
  const { isMobile } = useMediaQueryContext()

  const { name, treatments, globalDiscountActive, globalDiscount, discounts, minAge, maxAge } = plan

  const annualPrice = plan.annualPrice ?? 0
  const monthlyPrice = plan.monthlyPrice ?? 0

  return !isSite ? (
    // Enrollment Card
    <Card alignItems="center" paddingTop={24} elevation={1} hoverElevation={2} {...props} cursor="pointer">
      <Badge overflowWarp="break-word" wordWrap="break-word" size={500} height="auto" width={330} marginBottom={8} color="green">
        {name}
      </Badge>

      <ShownPrice term={term} dependent={dependent} plan={plan} />

      <UnorderedList icon="tick" iconColor="info" width={180}>
        {treatments.slice(0, 2).map((treatment, i) => (
          <ListItem key={i} textAlign="left">
            {treatment.quantity > 0 && `${treatment.quantity}x `}
            {treatment.name}
          </ListItem>
        ))}
        {(globalDiscountActive || !!discounts.length) && (
          <ListItem textAlign="left">
            {globalDiscountActive ? `${globalDiscount}% Off Other Procedures` : 'Additional Discounts'}
          </ListItem>
        )}
      </UnorderedList>
      <Button
        appearance="minimal"
        type="button"
        height={24}
        marginLeft={8}
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          showContractSheet()
        }}
      >
        More Details
      </Button>
    </Card>
  ) : (
    // Marketing Site Card
    <Card padding={24} alignItems="center" cursor="pointer" elevation={1} hoverElevation={3} {...props}>
        <Heading size={800} fontSize={22} fontWeight="bold" marginBottom={24}>
        {name}
        </Heading>
      <Text marginBottom={isMobile ? 8 : 16}>{ageRangeToString({ min: minAge, max: maxAge })}</Text>
      <Heading size={800}>${Math.round(term === Types.PlanTerm.ANNUAL ? annualPrice : monthlyPrice)}</Heading>

      <Text marginBottom={isMobile ? 8 : 16}>Per {term === Types.PlanTerm.ANNUAL ? 'Year' : 'Month'}</Text>

      <UnorderedList icon="tick" iconColor="info" width={180} textAlign="left" marginBottom={isMobile ? 8 : 0}>
        {treatments.slice(0, 5).map(treatment => (
          <ListItem key={treatment.id}>
            {treatment.quantity > 0 && `${treatment.quantity}x `}
            {treatment.name}
          </ListItem>
        ))}
        {(globalDiscountActive || !!discounts.length) && (
          <ListItem>
            {globalDiscountActive ? `${globalDiscount}% Off Other Procedures` : 'Additional Discounts'}
          </ListItem>
        )}
      </UnorderedList>
      <Button appearance="minimal" marginBottom={16} onClick={() => showContractSheet()}>
        View More Details
      </Button>
      <Link to={`/${providerParam}/enroll`}>
        {/* <Button
          appearance="primary"
          intent={siteColor && colorMap[siteColor].buttonIntent}
          height={48}
          paddingX={48}
          textAlign="center"
        >
          Sign Up
        </Button> */}
        <button style={{backgroundColor: "#306584", height: "50px", width:"120px", border: "0px", color:"white", fontSize:"1rem", borderRadius:"5px", cursor: "pointer", boxShadow:"2px 2px 6px #888"}}>
                Sign Up 
        </button>
      </Link>
    </Card>
  )
}

const ShownPrice = ({
  term,
  dependent,
  plan
}: {
  term: Types.PlanTerm
  dependent: boolean
  plan: {
    annualPrice: number | null
    annualDependentPrice: number | null
    monthlyPrice: number | null
    monthlyDependentPrice: number | null
    dependentDiscount: number | null
  }
}) => {
  const price = getShownPrice(term, dependent, plan)
  const { dependentDiscount } = plan

  return (
    <>
      <Pane display="flex" alignItems="flex-end">
        <Heading size={600}>${Math.round(price)}</Heading>
        <Heading size={500} marginBottom={2} marginLeft={2}>
          {` / ${term === Types.PlanTerm.ANNUAL ? 'yr' : 'mo'}`}
        </Heading>
      </Pane>
      {dependent && !!dependentDiscount && <Heading size={500}> ({dependentDiscount}% Discount)</Heading>}
    </>
  )
}
