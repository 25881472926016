import gql from 'graphql-tag'

export const GET_GLOBAL = gql`
  query Global {
    meUser {
      id
      firstName
      lastName
      showWelcome
      google {
        email
        role
      }
      accounts {
        id
        name
      }
    }
    account {
      id
      code
      name
      acceptPayments
      clearentPaymentKey
      merchantNumber
      type
      status
    }
  }
`
