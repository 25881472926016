import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Heading, toaster, Text, RadioGroup as RadioButton } from 'evergreen-ui'

import { TextInputField, RadioGroup, FileInputField, Card, CardHeader, Button, FormError, Icon } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { ADD_VIDEO, DELETE_VIDEO, UPDATE_VIDEO } from 'graphql/_video'

import { VideoStatus } from 'types'

import { storage } from 'firebase/app'
import cuid from 'cuid'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  video?: {
    id: string
    index: number
    title: string
    url: string
    status: VideoStatus
  }
}

const VideoSheet = ({ isShown, setIsShown, video }: Props) => {
  const showConfirmDialog = useModal('confirm')
 
  const [createVideo, createStatus] = useMutation<Types.addVideo, Types.addVideoVariables>(ADD_VIDEO, {
    // refetchQueries: ['videoSearch']
  })

  useEffect(() => {
    if (createStatus.error) {
      toaster.danger('Unable to create Video')
      createStatus.error = undefined;
    }
    else if (createStatus.data && !createStatus.loading) {
      setIsShown(false)
      toaster.success(`Video successfully created!`)
    }
  }, [createStatus, setIsShown])

  const [updateVideo, updateStatus] = useMutation<Types.updateVideo, Types.updateVideoVariables>(UPDATE_VIDEO, {
    // refetchQueries: ['videoSearch']
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update Video')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`Video successfully updated!`)
    }
  }, [updateStatus, setIsShown])

  const [deletevideo, deleteStatus] = useMutation<Types.deleteVideo, Types.deleteVideoVariables>(DELETE_VIDEO, {
    // refetchQueries: ['videoSearch']
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete Video')
    if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.danger(`Video successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])


  const [uploadVideo, setUploadVideo] = useState<boolean>(video ? false : true);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [videoError, setVideoError] = useState<string>('');
  const [videoUploading, setVideoUploading] = useState<boolean>(false)

  const uploadFile = (file: any) => {
    console.log("file[0]?.type",);

    if (file[0]?.type.split('/')[0] === 'video') {
      setVideoUploading(true)
      const path = cuid()
      const fileName = `${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER}/videos/${path}`

      storage()
        .ref(fileName)
        .put(file[0], { customMetadata: { path } })
        .then((res) => setVideoUploading(false))

      const URL = `https://firebasestorage.googleapis.com/v0/b/trublu-app/o/${process.env.REACT_APP_FIREBASE_STORAGE_FOLDER}%2Fvideos%2F${path}?alt=media&token=2d1c30e9-4efc-446a-8625-124cb338e4fd`;

      setVideoUrl(URL)
      setVideoError("")
    } else {
      setVideoError("Please Select valid video file")
    }
  };

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          video
            ? video
            : {
              index: 0,
              title: '',
              url: '',
              status: VideoStatus.ACTIVE
            }
        }
        onSubmit={({ ...userFields }) => {
          if (uploadVideo && !videoUrl) {
            setVideoError("Please upload video")
            return
          }

          if (!createStatus.error) {
            showConfirmDialog({
              body: `Are you sure you want to ${video ? 'update' : 'create'} this Video?`,
              confirm: () => {
                if (video) {
                  updateVideo({
                    variables: {
                      id: video.id,
                      index: userFields.index,
                      title: userFields.title,
                      url: uploadVideo ? videoUrl ? videoUrl : userFields.url : userFields.url,
                      status: userFields.status
                    }
                  })
                } else {
                  createVideo({
                    variables: {
                      index: userFields.index,
                      title: userFields.title,
                      url: uploadVideo ? videoUrl ? videoUrl : userFields.url : userFields.url,
                      status: userFields.status
                    }
                  })
                }
              }
            })
          }
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Title is required'),
          url: uploadVideo ? Yup.string() : Yup.string().required('Url is required').url(),
          index: Yup.number().required('Index is required'),
          status: Yup.mixed().oneOf([VideoStatus.ACTIVE, VideoStatus.INACTIVE])
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {video ? (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Update video</Heading>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add video</Heading>
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <TextInputField name="title" label="Title" type="text" placeholder="Title" />
                <CustomRadioButton
                  name="status"
                  label=""
                  size={16}
                  marginBottom={0}
                  value={uploadVideo ? 'video' : 'url'}
                  onChange={(e: string) => { setUploadVideo(e === 'video' ? true : false) }}
                  options={[
                    { label: 'Video', value: 'video' },
                    { label: 'URL', value: 'url' }
                  ]}
                />
                {
                  uploadVideo ?
                    <>
                      <FileInputField name="video" label="Video" onChange={(e: any) => { uploadFile(e) }} placeholder="Video" disabled={videoUploading} />
                      {videoError && <div><Icon color="red" icon={['fad', 'times']} marginRight={4} /><Text color="red">{videoError}</Text></div>}
                    </>
                    :
                    <TextInputField name="url" label="URL" type="text" placeholder="URL" />
                }
                <TextInputField name="index" label="Index" type="number" placeholder="Index" />
                <RadioGroup
                  name="status"
                  label="Status"
                  size={16}
                  marginBottom={0}
                  options={[
                    { label: 'Active', value: VideoStatus.ACTIVE },
                    { label: 'Inactive', value: VideoStatus.INACTIVE }
                  ]}
                />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {video ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={updateStatus.loading || updateStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this video?',
                        confirm: () => {
                          deletevideo({ variables: { id: video.id } })
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                    disabled={videoUploading}
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    isLoading={updateStatus.loading || !!updateStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                    disabled={videoUploading}
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={createStatus.loading || !!createStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  disabled={videoUploading}
                >
                  Add video
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default VideoSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`

const CustomRadioButton = styled(RadioButton)`
label:last-child {
  margin-bottom: 0;
  margin-left:20px;
}
display:flex;
`