import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Helmet from 'react-helmet'

import FirebaseProvider from 'components/firebase-provider'
import { AuthCheck } from 'reactfire'

import ApolloProvider from 'components/apollo-provider'
import { useApolloClient } from '@apollo/react-hooks'
import { GlobalProvider } from 'components/global-provider'

import { MediaQueryProvider, FullScreen, StripeElementsProvider, TokenProvider } from '@pearly/lib'

import LoginView from 'views/login'
import OnboardingView from 'views/onboarding'
import InvitationView from 'views/invitation'
import ResetPasswordView from 'views/resetPassword'

import { ModalProvider } from 'components/modal-provider'

const Layout = lazy(() => import('components/layout'))

const stripeConfig = {
  apiKey: process.env.REACT_APP_STRIPE_KEY ?? ''
}

const clearentHPPScript = process.env.REACT_APP_CLEARENT_HPP_SCRIPT //"https://hpp-sb.clearent.net/js/clearent.js"

export default () => {
  return (
    // <React.StrictMode>
    <>
      <Helmet>
        <script type="text/javascript" id="clearent-hpp" src={`${clearentHPPScript}`}></script>
        <script src={'/clearent-init.js'} type="text/javascript" />
      </Helmet>
      <Suspense fallback={<FullScreen display="flex" justifyContent="center" alignItems="center" />}>
        <Router>
          <FirebaseProvider>
            <TokenProvider>
              <ApolloProvider>
                <MediaQueryProvider>
                  <ApolloConsumer />
                </MediaQueryProvider>
              </ApolloProvider>
            </TokenProvider>
          </FirebaseProvider>
        </Router>
      </Suspense>
    </>
    // </React.StrictMode>
  )
}

const ApolloConsumer = () => {
  const apolloClient = useApolloClient()

  return (
    <Switch>
      <Route path="/onboarding">
        <ModalProvider apolloClient={apolloClient}>
          <OnboardingView />
        </ModalProvider>
      </Route>
      <Route path="/reset-password">
        <ResetPasswordView />
      </Route>
      <Route path="/invitation">
        <InvitationView />
      </Route>
      <Route path="/">
        <AuthCheck
          fallback={
            <>
              <LoginView />
              <Redirect from="*" to="/" />
            </>
          }
        >
          <AuthCheck requiredClaims={{ onboardingStep: 'COMPLETE' }} fallback={<Redirect to="/onboarding" />}>
            <GlobalProvider>
              <StripeElementsProvider apiKey={stripeConfig.apiKey}>
                <ModalProvider apolloClient={apolloClient}>
                  <Layout />
                </ModalProvider>
              </StripeElementsProvider>
            </GlobalProvider>
          </AuthCheck>
        </AuthCheck>
      </Route>
    </Switch>
  )
}
