import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { UPSERT_DENTIST, DELETE_DENTIST, GET_DENTISTS } from 'graphql/_dentist-sheet'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Avatar, Text, Heading, toaster, Tooltip } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError } from '@pearly/lib'

import DentistFields from 'components/_fields/dentist-fields'
import { useModal } from 'components/modal-provider'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  dentist?: {
    id: string
    salutation: Types.Salutation
    name: string
    degree: Types.Degree
  }
}

const DentistSheet = ({ isShown, setIsShown, dentist }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [upsertDentist, upsertStatus] = useMutation<Types.UpsertDentist, Types.UpsertDentistVariables>(UPSERT_DENTIST, {
    update: (cache, { data }) => {
      // return if performing single dentist update (Apollo automatically updates cache)
      if (dentist || !data) return

      const cachedData = cache.readQuery<Types.GetDentists>({ query: GET_DENTISTS })
      if (cachedData) {
        cache.writeQuery({
          query: GET_DENTISTS,
          data: { dentists: cachedData.dentists.concat([data.upsertDentist]) }
        })
      }
    }
  })

  useEffect(() => {
    if (upsertStatus.error) toaster.danger(`Unable to ${dentist ? 'update' : 'create'} dentist`)
    else if (upsertStatus.data && !upsertStatus.loading) {
      setIsShown(false)
      toaster.success(`Dentist successfully ${dentist ? 'updated' : 'created'}!`)
    }
  }, [upsertStatus, dentist, setIsShown])

  const [deleteDentist, deleteStatus] = useMutation<Types.DeleteDentist, Types.DeleteDentistVariables>(DELETE_DENTIST, {
    variables: { id: dentist?.id ?? '' },
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetDentists>({ query: GET_DENTISTS })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_DENTISTS,
          data: { dentists: cachedData.dentists.filter(dentist => dentist.id !== data.deleteDentist.id) }
        })
      }
    }
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete dentist')
    else if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.warning(`Dentist successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          dentist ? { ...dentist } : { salutation: '' as Types.Salutation, name: '', degree: '' as Types.Degree }
        }
        onSubmit={({ ...dentistFields }) => {
          showConfirmDialog({
            body: `Are you sure you want to ${dentist ? 'update' : 'create'} this dentist?`,
            confirm: () => {
              upsertDentist({
                variables: {
                  id: dentist ? dentist.id : null,
                  ...dentistFields
                }
              })
            }
          })
        }}
        validationSchema={Yup.object({
          salutation: Yup.string().required('Salutation is required'),
          name: Yup.string()
            .min(4, 'Name must be at least 4 characters')
            .required('Name is required'),
          degree: Yup.string().required('Degree is required')
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {dentist ? (
              // <div style={{height:"70px"}}>
              <CardHeader gridArea="header">
                {/* Match color to table */}
                <Avatar name={dentist.name} size={40} color="blue" />
                <Pane marginLeft={16}>
                  <Tooltip content={`${dentist.salutation}. ${dentist.name}${dentist.degree !== Types.Degree.NONE ? `, ${dentist.degree}` : ''
                    }`}>
                    <Heading className={'dentistHearder'} size={600}>{`${dentist.salutation}. ${dentist.name}${dentist.degree !== Types.Degree.NONE ? `, ${dentist.degree}` : ''
                      }`}</Heading>
                  </Tooltip>
                  <Text size={400}>Dentist Details</Text>
                </Pane>
              </CardHeader>
              // </div>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add Dentist</Heading>
                <Paragraph size={400}>This dentist will be listed on your landing page</Paragraph>
              </CardHeader>
            )}
            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <DentistFields />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {dentist ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={upsertStatus.loading || upsertStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this dentist?',
                        confirm: () => {
                          deleteDentist()
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    isLoading={upsertStatus.loading || !!upsertStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={upsertStatus.loading || !!upsertStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'plus']}
                >
                  Add Dentist
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default DentistSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
