
import React from 'react'

import { Pane, Text } from 'evergreen-ui'

const ServiceContract = () => {

    return (
        <Pane>
            <Text>
                <p>
                    This Agreement (this "<strong>Agreement</strong>"), effective as of the
                    date executed by Customer (the "Effective Date"), is by and between TruBlu
                    Plan for Health, LLC, a Delaware Corporation with offices located at 7804-C
                    Fairview Road, Suite 190, Charlotte, NC 28226 ("Provider"), and Practice
                    decribed in Exhibit “A” hereto ("Customer"). Provider and Customer may be
                    referred to herein collectively as the "Parties" or individually as a
                    "Party."
                </p>
                <p>
                    RECITALS
                </p>
                <p>
                    Provider provides access to the Services to its customers; and Customer
                    desires to access the Services.
                </p>
                <p>
                    Provider desires to provide Customer access to the Platform Services,
                    subject to the terms and conditions of this Agreement.
                </p>
                <p>
                    NOW, THEREFORE, in consideration of the mutual covenants, terms, and
                    conditions set forth herein, and for other good and valuable consideration,
                    the receipt and sufficiency of which are hereby acknowledged, the Parties
                    agree as follows:
                </p>
                <ol>
                    <li>
                        Definitions.
                    </li>
                    <ol type="a">
                        <li>
                            "<strong>Aggregated Statistics</strong>" means data and information
                            related to Customer's use of the Services that is used by Provider
                            in an aggregate and anonymized manner, including to compile
                            statistical and performance information related to the provision
                            and operation of the Services.
                        </li>
                        <li>
                            "<strong>Authorized User</strong>" means Customer's employees,
                            consultants, contractors, and agents (i) who are authorized by
                            Customer to access and use the Services under the rights granted to
                            Customer pursuant to this Agreement and (ii) for whom access to the
                            Platform and use of the Services has been purchased hereunder.
                        </li>
                        <li>
                            "<strong>Customer Data</strong>" means, other than Aggregated
                            Statistics, information, data, and other content, in any form or
                            medium, that is submitted, posted, or otherwise transmitted by or
                            on behalf of Customer or an Authorized User through the Services.
                        </li>
                        <li>
                            "<strong>Documentation</strong>" means, where and when available,
                            Provider's user manuals, guides or other instructional materials
                            relating to the Services provided by Provider to Customer.
                        </li>
                        <li>
                            "<strong>Provider IP</strong>" means the Oral Wellness Membership
                            Program together with all associated intellectual property,
                            including without limitation the Platform and Services as defined
                            herein, the Documentation, and any and all intellectual property
                            provided to Customer or any Authorized User in connection with the
                            foregoing. For the avoidance of doubt, Provider IP includes
                            Aggregated Statistics and any information, data, or other content
                            derived from Provider's monitoring of Customer's access to the
                            Platform or use of the Services, but does not include Customer
                            Data.
                        </li>
                        <li>
                            <strong>“Platform”</strong>
                            means the website, software and associated services provided by the
                            TruBlu Plan for Health, LLC Platform offering described in            <strong>Exhibit A</strong>.
                        </li>
                        <li>
                            <strong>“Services” </strong>
                            means the software and associated technical support provided as a
                            service to Customer upon execution of this Agreement, and payment
                            of setup and monthly fees.
                        </li>
                        <li>
                            "<strong>Third-Party Products</strong>" means any third-party
                            products described in <strong>Exhibit A</strong><strong> </strong>
                            provided with or incorporated into the Services.
                        </li>
                    </ol>
                    <li>
                        Access and Use.
                    </li>
                    <ol type="a">
                        <li>
                            Provision of Access. Subject to and conditioned on Customer's
                            payment of Fees and compliance with the terms and conditions of
                            this Agreement, Provider hereby grants Customer a non-exclusive,
                            non-transferable (except in compliance with Section 13(g)) right to
                            access the Platform and use the Services during the Term, solely
                            for use by Authorized Users in accordance with the terms and
                            conditions herein. Such use is limited to Customer's internal use.
                            Provider shall provide to Customer the necessary passwords and
                            network links or connections to allow Customer to access the
                            Platform and use the Services.
                        </li>
                        <li>
                            Documentation License. Subject to the terms and conditions
                            contained in this Agreement, Provider hereby grants to Customer a
                            non-exclusive, non-sublicensable, non-transferable (except in
                            compliance with Section 13(g)) license to use the Documentation
                            during the Term solely for Customer's internal business purposes in
                            connection with its access of the Platform and use of the Services.
                        </li>
                        <li>
                            Use Restrictions. Customer shall not access the Platform or use the
                            Services for any purposes beyond the scope of the access granted in
                            this Agreement. Customer shall not at any time, directly or
                            indirectly, and shall not permit any Authorized Users to: (i) copy,
                            modify, or create derivative works of the Platform, Services or
                            Documentation, in whole or in part; (ii) rent, lease, lend, sell,
                            license, sublicense, assign, distribute, publish, transfer, or
                            otherwise make available the Platform, Services or Documentation;
                            (iii) reverse engineer, disassemble, decompile, decode, adapt, or
                            otherwise attempt to derive or gain access to any software
                            component of the Platform or Services, in whole or in part; (iv)
                            remove any proprietary notices from the Platform, Services or
                            Documentation; or (v) access the Platform or use the Services or
                            Documentation in any manner or for any purpose that infringes,
                            misappropriates, or otherwise violates any intellectual property
                            right or other right of any person, or that violates any applicable
                            law.
                        </li>
                        <li>
                            Reservation of Rights. Provider reserves all rights not expressly
                            granted to Customer in this Agreement. Except for the limited
                            rights and licenses expressly granted under this Agreement, nothing
                            in this Agreement grants, by implication, waiver, estoppel, or
                            otherwise, to Customer or any third party any intellectual property
                            rights or other right, title, or interest in or to the Provider IP.
                        </li>
                        <li>
                            Suspension. Notwithstanding anything to the contrary in this
                            Agreement, Provider may temporarily suspend Customer's and any
                            Authorized User's access to the Platform any portion or all of the
                            Services for non-payment of the monthly service, or, if: (i)
                            Provider reasonably determines that (A) there is a threat or attack
                            on any of the Provider IP; (B) Customer's or any Authorized User's
                            use of the Provider IP disrupts or poses a security risk to the
                            Provider IP or to any other customer or vendor of Provider; (C)
                            Customer, or any Authorized User, is using the Provider IP for
                            fraudulent or illegal activities; (D) subject to applicable law,
                            Customer has ceased to continue its business in the ordinary
                            course, made an assignment for the benefit of creditors or similar
                            disposition of its assets, or become the subject of any bankruptcy,
                            reorganization, liquidation, dissolution, or similar proceeding; or
                            (E) Provider's provision of the Services to Customer or any
                            Authorized User is prohibited by applicable law; (ii) any vendor of
                            Provider has suspended or terminated Provider's access to or use of
                            any third-party services or products required to enable Customer to
                            access the Services; or (iii) in accordance with Section 5(a) (any
                            such suspension described in subclause (i), (ii), or (iii), a
                            “Service Suspension”). Provider shall use commercially reasonable
                            efforts to provide written notice of any Service Suspension to
                            Customer and to provide updates regarding resumption of access to
                            the Platform and use of the Services following any Service
                            Suspension. Provider shall use commercially reasonable efforts to
                            resume providing access to the Platform and use of the Services as
                            soon as reasonably possible after the event giving rise to the
                            Service Suspension is cured. Provider will have no liability for
                            any damage, liabilities, losses (including any loss of data or
                            profits), or any other consequences that Customer or any Authorized
                            User may incur as a result of a Service Suspension.
                        </li>
                        <li>
                            Aggregated Statistics. Notwithstanding anything to the contrary in
                            this Agreement, Provider may monitor Customer's use of the Services
                            and collect and compile Aggregated Statistics. As between Provider
                            and Customer, all right, title, and interest in Aggregated
                            Statistics, and all intellectual property rights therein, belong to
                            and are retained solely by Provider. Customer acknowledges that
                            Provider may compile Aggregated Statistics based on Customer Data
                            input into the Services. Customer agrees that Provider may (i) make
                            Aggregated Statistics publicly available in compliance with
                            applicable law, and (ii) use Aggregated Statistics to the extent
                            and in the manner permitted under applicable law; provided that
                            such Aggregated Statistics do not identify Customer or Customer's
                            Confidential Information.
                        </li>
                    </ol>
                    <li>
                        Customer Responsibilities.
                    </li>
                    <ol type="a">
                        <li>
                            General. Customer is responsible and liable for all uses of the
                            Services and Documentation resulting from access provided by
                            Customer, directly or indirectly, whether such access or use is
                            permitted by or in violation of this Agreement. Without limiting
                            the generality of the foregoing, Customer is responsible for all
                            acts and omissions of Authorized Users, and any act or omission by
                            an Authorized User that would constitute a breach of this Agreement
                            if taken by Customer will be deemed a breach of this Agreement by
                            Customer. Customer shall use reasonable efforts to make all
                            Authorized Users aware of this Agreement's provisions as applicable
                            to such Authorized User's use of the Services, and shall cause
                            Authorized Users to comply with such provisions.
                        </li>
                        <li>
                            Third-Party Products. Provider may from time to time make
                            Third-Party Products available to Customer. For purposes of this
                            Agreement, such Third-Party Products are subject to their own terms
                            and conditions and the applicable flow-through provisions referred
                            to in Exhibit A. If Customer does not agree to abide by the
                            applicable terms for any such Third-Party Products, then Customer
                            should not install or use such Third-Party Products.
                        </li>
                    </ol>
                    <li>
                        Service Availability.
                    </li>
                    <ol type="a">
                        <li>
                            Availability. Subject to the terms and conditions of this
                            Agreement, Provider shall use commercially reasonable efforts to
                            make the Platform and Services available on a 24 hour/7 days per
                            week basis. Customer acknowledges that certain interruption of the
                            Services may be beyond our control, or that the Services may be
                            periodically unavailable for maintenance purposes.
                        </li>
                    </ol>
                    <li>
                        Fees and Payment.
                    </li>
                    <ol type="a">
                        <li>
                            Customer shall pay Provider fees on a monthly basis ("Fees") as set
                            forth in <strong>Exhibit A</strong> without offset or deduction.
                            All fees are non-refundable. Customer shall make all monthly
                            payments by credit or debit card hereunder in US dollars in advance
                            of any particular service month. If Customer fails to make any
                            payment when due, without limiting Provider's other rights and
                            remedies: (i) Provider may charge interest on the past due amount
                            at the rate of 1.5% per month calculated daily and compounded
                            monthly or, if lower, the highest rate permitted under applicable
                            law; (ii) Customer shall reimburse Provider for all reasonable
                            costs incurred by Provider in collecting any late payments or
                            interest, including attorneys' fees, court costs, and collection
                            agency fees; and (iii) if such failure continues for 10 days or
                            more, Provider may suspend Customer's and its Authorized Users'
                            access to any portion or all of the Services until such amounts are
                            paid in full.
                        </li>
                        <li>
                            Provider Fees paid by Customer to Provider do not include payment
                            card merchant processing fees incurred through Customer’s use of
                            this Platform or the Services. Customer acknowledges and agrees
                            that Customer has sole and exclusive responsibility for payment of
                            any such merchant processing fees.
                        </li>
                        <li>
                            Customer shall pay the initial non-refundable set up fee (“Set Up
                            Fee”) as defined in [Exhibit “A”] upon execution of this Agreement.
                        </li>
                        <li>
                            Fee Increases. Provider may, from time to time, and not more
                            frequently than annually, impose reasonable fee increases for
                            access to the Platform and use of the Services. Any fee increase
                            will be prospective, and apply to Customer’s next monthly renewal.
                        </li>
                        <li>
                            Taxes. All Fees and other amounts payable by Customer under this
                            Agreement are exclusive of taxes and similar assessments. Customer
                            is responsible for all sales, use, and excise taxes, and any other
                            similar taxes, duties, and charges of any kind imposed by any
                            federal, state, or local governmental or regulatory authority on
                            any amounts payable by Customer hereunder, other than any taxes
                            imposed on Provider's income.
                        </li>
                    </ol>
                    <li>
                        Confidential Information. From time to time during the Term, either
                        Party may disclose or make available to the other Party information
                        about its business affairs, products, confidential intellectual
                        property, trade secrets, third-party confidential information, and
                        other sensitive or proprietary information, including protected health
                        information and other personally identifiable information, whether
                        orally or in written, electronic, or in written or electronic form or
                        media, and whether or not marked, designated, or otherwise identified
                        as "confidential" (collectively, "Confidential Information").
                        Confidential Information does not include information that, at the time
                        of disclosure is: (a) in the public domain; (b) known to the receiving
                        Party at the time of disclosure; (c) rightfully obtained by the
                        receiving Party on a non-confidential basis from a third party; or (d)
                        independently developed by the receiving Party. The receiving Party
                        shall not disclose the disclosing Party's Confidential Information to
                        any person or entity, except to the receiving Party's employees who
                        have a need to know the Confidential Information for the receiving
                        Party to exercise its rights or perform its obligations hereunder.
                        Notwithstanding the foregoing, each Party may disclose Confidential
                        Information to the limited extent required (i) in order to comply with
                        the order of a court or other governmental body, or as otherwise
                        necessary to comply with applicable law, provided that the Party making
                        the disclosure pursuant to the order shall first have given written
                        notice to the other Party and made a reasonable effort to obtain a
                        protective order; or (ii) to establish a Party's rights under this
                        Agreement, including to make required court filings. On the expiration
                        or termination of the Agreement, the receiving Party shall promptly
                        return to the disclosing Party all copies, whether in written,
                        electronic, or other form or media, of the disclosing Party's
                        Confidential Information, or destroy all such copies and certify in
                        writing to the disclosing Party that such Confidential Information has
                        been destroyed. Each Party's obligations of non-disclosure with regard
                        to Confidential Information are effective as of the Effective Date and
                        will expire five years from the date first disclosed to the receiving
                        Party; provided, however, with respect to any Confidential Information
                        that constitutes a trade secret (as determined under applicable law),
                        such obligations of non-disclosure will survive the termination or
                        expiration of this Agreement for as long as such Confidential
                        Information remains subject to trade secret protection under applicable
                        law.
                    </li>
                    <li>
                        Compliance with Applicable Laws and Regulations. Both Provider and
                        Customer hereby represent, warrant and covenant that each has
                        commercially reasonable security and complies with industry best
                        practices to comply with applicable federal and state laws directed to
                        protecting the confidentiality, integrity and availability of protected
                        health information and other personally identifiable or personal
                        sensitive information.
                    </li>
                    <li>
                        Intellectual Property Ownership; Feedback.
                    </li>
                    <ol type="a">
                        <li>
                            Provider IP. Customer acknowledges that, as between Customer and
                            Provider, Provider owns all right, title, and interest, including
                            all intellectual property rights, in and to the Provider IP and,
                            with respect to Third-Party Products, the applicable third-party
                            providers own all right, title, and interest, including all
                            intellectual property rights, in and to the Third-Party Products.
                        </li>
                        <li>
                            Customer Data. Provider acknowledges that, as between Provider and
                            Customer, Customer owns all right, title, and interest, including
                            all intellectual property rights, in and to the Customer Data.
                            Customer hereby grants to Provider a non-exclusive, royalty-free,
                            worldwide license to reproduce, distribute, and otherwise use and
                            display the Customer Data and perform all acts with respect to the
                            Customer Data as may be necessary for Provider to provide the
                            Services to Customer, and a non-exclusive, perpetual, irrevocable,
                            royalty-free, worldwide license to reproduce, distribute, modify,
                            and otherwise use and display Customer Data incorporated within the
                            Aggregated Statistics.
                        </li>
                        <li>
                            Feedback. If Customer or any of its employees or contractors sends
                            or transmits any communications or materials to Provider by mail,
                            email, telephone, or otherwise, suggesting or recommending changes
                            to the Provider IP, including without limitation, new features or
                            functionality relating thereto, or any comments, questions,
                            suggestions, or the like ("Feedback"), Provider is free to use such
                            Feedback irrespective of any other obligation or limitation between
                            the Parties governing such Feedback. Customer hereby assigns to
                            Provider on Customer's behalf, and on behalf of its employees,
                            contractors and/or agents, all right, title, and interest in, and
                            Provider is free to use, without any attribution or compensation to
                            any party, any ideas, know-how, concepts, techniques, or other
                            intellectual property rights contained in the Feedback, for any
                            purpose whatsoever, although Provider is not required to use any
                            Feedback.
                        </li>
                    </ol>
                    <li>
                        Limited Warranty and Warranty Disclaimer.
                    </li>
                    <ol type="a">
                        <li>
                            Provider warrants that the Services will conform in all material
                            respects to the service levels set forth in Exhibit B when accessed
                            and used in accordance with the Documentation. Provider does not
                            make any representations or guarantees regarding uptime or
                            availability of the Services unless specifically identified in
                            Exhibit B. The remedies set forth in Exhibit B are Customer's sole
                            remedies and Provider's sole liability under the limited warranty
                            set forth in this Section 9(a). THE FOREGOING WARRANTY DOES NOT
                            APPLY, AND PROVIDER STRICTLY DISCLAIMS ALL WARRANTIES, WITH RESPECT
                            TO ANY THIRD-PARTY PRODUCTS.
                        </li>
                        <li>
                            EXCEPT OTHERWISE PROVIDED HEREIN, THE PROVIDER IP, PLATFORM AND
                            SERVICES ARE PROVIDED "AS IS" AND PROVIDER HEREBY DISCLAIMS ALL
                            WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE.
                            PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                            NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF
                            DEALING, USAGE, OR TRADE PRACTICE. PROVIDER MAKES NO WARRANTY OF
                            ANY KIND THAT THE PROVIDER IP, OR ANY PRODUCTS OR RESULTS OF THE
                            USE THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S
                            REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
                            RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER
                            SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE,
                            OR ERROR FREE.
                        </li>
                    </ol>
                    <li>
                        Indemnification.
                    </li>
                    <ol type="a">
                        <li>
                            Provider Indemnification.
                        </li>
                        <ol type="i">
                            <li>
                                Provider shall indemnify, defend, and hold harmless Customer
                                from and against any and all losses, damages, liabilities,
                                costs (including reasonable attorneys' fees) ("Losses")
                                incurred by Customer resulting from any third-party claim,
                                suit, action, or proceeding ("Third-Party Claim") that the
                                Platform or the Services, or any use of the Services in
                                accordance with this Agreement, infringes or misappropriates
                                such third party's US intellectual property rights, provided
                                that Customer promptly notifies Provider in writing of the
                                claim, cooperates with Provider, and allows Provider sole
                                authority to control the defense and settlement of such claim.
                            </li>
                            <li>
                                If such a claim is made or appears possible, Customer agrees to
                                permit Provider, at Provider's sole discretion, to (A) modify
                                or replace the Platform and/or the Services, or component or
                                part thereof, to make it non-infringing, or (B) obtain the
                                right for Customer to continue use. If Provider determines that
                                neither alternative is reasonably available, Provider may
                                terminate this Agreement, in its entirety or with respect to
                                the affected component or part, effective immediately on
                                written notice to Customer.
                            </li>
                            <li>
                                This Section 10(a) will not apply to the extent that the
                                alleged infringement arises from: (A) use of the Services in
                                combination with data, software, hardware, equipment, or
                                technology not provided by Provider or authorized by Provider
                                in writing; (B) modifications to the Services not made by
                                Provider; or (C) Third-Party Products.
                            </li>
                        </ol>
                        <li>
                            Customer Indemnification. Customer shall indemnify, hold harmless,
                            and, at Provider's option, defend Provider from and against any
                            Losses resulting from any Third-Party Claim that the Customer Data,
                            or any use of the Customer Data in accordance with this Agreement,
                            infringes or misappropriates such third party's US intellectual
                            property rights and any Third-Party Claims based on Customer's or
                            any Authorized User's (i) negligence or willful misconduct; (ii)
                            use of the Services in a manner not authorized by this Agreement;
                            (iii) use of the Services in combination with data, software,
                            hardware, equipment, or technology not provided by Provider or
                            authorized by Provider in writing; or (iv) modifications to the
                            Services not made by Provider, provided that Customer may not
                            settle any Third-Party Claim against Provider unless Provider
                            consents to such settlement, and further provided that Provider
                            will have the right, at its option, to defend itself against any
                            such Third-Party Claim or to participate in the defense thereof by
                            counsel of its own choice.
                        </li>
                        <li>
                            Sole Remedy. THIS SECTION 10 SETS FORTH CUSTOMER'S SOLE REMEDIES
                            AND PROVIDER'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL,
                            THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES INFRINGE,
                            MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY
                            RIGHTS OF ANY THIRD PARTY. IN NO EVENT WILL PROVIDER'S LIABILITY
                            UNDER THIS SECTION 10 EXCEED THREE MONTHS OF SERVICE FEES PAID TO
                            PROVIDER, EXCLUDING SETUP FEES.
                        </li>
                    </ol>
                    <li>
                        Limitations of Liability. IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR
                        IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY,
                        INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
                        LIABILITY, AND OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL,
                        INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b)
                        INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION,
                        REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION; (d) USE,
                        INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF ANY DATA,
                        OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS
                        OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF
                        THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
                        WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER'S AGGREGATE
                        LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL
                        OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING
                        NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE EXCEED THE TOTAL AMOUNTS
                        PAID TO PROVIDER UNDER THIS AGREEMENT IN THE TWELVE MONTH PERIOD
                        PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR $3,000.00, WHICHEVER IS
                        LESS.
                    </li>
                    <li>
                        Term and Termination.
                    </li>
                    <ol type="a">
                        <li>
                            Term. The term of this Agreement begins on the Effective Date and,
                            unless terminated earlier pursuant to this Agreement's express
                            provisions, will continue in effect and renew automatically on a
                            monthly basis until terminated by either party hereto upon written
                            notice non-renewal by a party to the other party at least 10 days
                            prior to the expiration of the then-current term (the "            <strong>Term</strong>").
                        </li>
                        <li>
                            Termination. In addition to any other express termination right set
                            forth in this Agreement:
                        </li>
                        <ol>
                            <li>
                                Provider may suspend or terminate this Agreement at its sole
                                option, effective on written notice to Customer, if Customer:
                                (A) fails to pay any amount when due hereunder, and such
                                failure continues more than ten (10) days after Provider's
                                delivery of written notice thereof; or (B) breaches any of its
                                obligations under Section 2(c) or Section 6;
                            </li>
                            <li>
                                either Party may terminate this Agreement, effective on written
                                notice to the other Party, if the other Party materially
                                breaches this Agreement, and such breach: (A) is incapable of
                                cure; or (B) being capable of cure, remains uncured thirty 30
                                days after the non-breaching Party provides the breaching Party
                                with written notice of such breach; or
                            </li>
                            <li>
                                either Party may terminate this Agreement, effective
                                immediately upon written notice to the other Party, if the
                                other Party: (A) becomes insolvent or is generally unable to
                                pay, or fails to pay, its debts as they become due; (B) files
                                or has filed against it, a petition for voluntary or
                                involuntary bankruptcy or otherwise becomes subject,
                                voluntarily or involuntarily, to any proceeding under any
                                domestic or foreign bankruptcy or insolvency law; (C) makes or
                                seeks to make a general assignment for the benefit of its
                                creditors; or (D) applies for or has appointed a receiver,
                                trustee, custodian, or similar agent appointed by order of any
                                court of competent jurisdiction to take charge of or sell any
                                material portion of its property or business.
                            </li>
                        </ol>
                        <li>
                            Effect of Expiration or Termination. Upon expiration or earlier
                            termination of this Agreement, Customer shall immediately
                            discontinue use of the Provider IP, not access the Platform or user
                            the Services and, without limiting Customer's obligations under
                            Section 6, Customer shall delete, destroy, or return all copies of
                            the Provider IP and certify in writing to the Provider that the
                            Provider IP has been deleted or destroyed. No expiration or
                            termination will affect Customer's obligation to pay all Fees that
                            may have become due before such expiration or termination or
                            entitle Customer to any refund.
                        </li>
                        <li>
                            Survival. This Section 11(d) and Sections 1, 5, 6, 8, 9(b), 10, 11,
                            and 13 survive any termination or expiration of this Agreement. No
                            other provisions of this Agreement survive the expiration or
                            earlier termination of this Agreement.
                        </li>
                    </ol>
                    <li>
                        Miscellaneous.
                    </li>
                    <ol type="a">
                        <li>
                            Entire Agreement. This Agreement, together with any other documents
                            incorporated herein by reference and all related Exhibits,
                            constitutes the sole and entire agreement of the Parties with
                            respect to the subject matter of this Agreement and supersedes all
                            prior and contemporaneous understandings, agreements, and
                            representations and warranties, both written and oral, with respect
                            to such subject matter. In the event of any inconsistency between
                            the statements made in the body of this Agreement, the related
                            Exhibits, and any other documents incorporated herein by reference,
                            the following order of precedence governs: (i) first, this
                            Agreement, excluding its Exhibits; (ii) second, the Exhibits to
                            this Agreement as of the Effective Date; and (iii) third, any other
                            documents incorporated herein by reference.
                        </li>
                        <li>
                            Notices. All notices, requests, consents, claims, demands, waivers,
                            and other communications hereunder (each, a "Notice") must be in
                            writing and addressed to the Parties at the addresses set forth on
                            the first page of this Agreement (or to such other address that may
                            be designated by the Party giving Notice from time to time in
                            accordance with this Section). All Notices must be delivered by
                            personal delivery, nationally recognized overnight courier (with
                            all fees pre-paid), facsimile or email (with confirmation of
                            transmission), or certified or registered mail (in each case,
                            return receipt requested, postage pre-paid). Except as otherwise
                            provided in this Agreement, a Notice is effective only: (i) upon
                            receipt by the receiving Party; and (ii) if the Party giving the
                            Notice has complied with the requirements of this Section.
                        </li>
                        <li>
                            Force Majeure. In no event shall either Party be liable to the
                            other Party, or be deemed to have breached this Agreement, for any
                            failure or delay in performing its obligations under this Agreement
                            (except for any obligations to make payments), if and to the extent
                            such failure or delay is caused by any circumstances beyond such
                            Party's reasonable control, including but not limited to acts of
                            God, pandemic, flood, fire, earthquake, explosion, war, terrorism,
                            invasion, riot or other civil unrest, strikes, labor stoppages or
                            slowdowns or other industrial disturbances, or passage of law or
                            any action taken by a governmental or public authority, including
                            imposing an embargo.
                        </li>
                        <li>
                            Amendment and Modification; Waiver. No amendment to or modification
                            of this Agreement is effective unless it is in writing and signed
                            by an authorized representative of each Party. No waiver by any
                            Party of any of the provisions hereof will be effective unless
                            explicitly set forth in writing and signed by the Party so waiving.
                            Except as otherwise set forth in this Agreement, (i) no failure to
                            exercise, or delay in exercising, any rights, remedy, power, or
                            privilege arising from this Agreement will operate or be construed
                            as a waiver thereof, and (ii) no single or partial exercise of any
                            right, remedy, power, or privilege hereunder will preclude any
                            other or further exercise thereof or the exercise of any other
                            right, remedy, power, or privilege.
                        </li>
                        <li>
                            Severability. If any provision of this Agreement is invalid,
                            illegal, or unenforceable in any jurisdiction, such invalidity,
                            illegality, or unenforceability will not affect any other term or
                            provision of this Agreement or invalidate or render unenforceable
                            such term or provision in any other jurisdiction. Upon such
                            determination that any term or other provision is invalid, illegal,
                            or unenforceable, the Parties shall negotiate in good faith to
                            modify this Agreement so as to effect their original intent as
                            closely as possible in a mutually acceptable manner in order that
                            the transactions contemplated hereby be consummated as originally
                            contemplated to the greatest extent possible.
                        </li>
                        <li>
                            Governing Law; Submission to Jurisdiction. This Agreement is
                            governed by and construed in accordance with the internal laws of
                            the State of New Jersey without giving effect to any choice or
                            conflict of law provision or rule that would require or permit the
                            application of the laws of any jurisdiction other than those of the
                            State of New Jersey. Any legal suit, action, or proceeding arising
                            out of or related to this Agreement or the licenses granted
                            hereunder shall be instituted exclusively in the federal courts of
                            the United States or the courts of the State of New Jersey in each
                            case located in or nearest to the city of Roseland and County of
                            Essex, and each Party irrevocably submits to the exclusive
                            jurisdiction and venue of such courts in any such suit, action, or
                            proceeding.
                        </li>
                        <li>
                            Assignment. Customer may not assign any of its rights or delegate
                            any of its obligations hereunder, in each case whether voluntarily,
                            involuntarily, by operation of law or otherwise, without the prior
                            written consent of Provider. Any purported assignment or delegation
                            in violation of this Section will be null and void. No assignment
                            or delegation will relieve the assigning or delegating Party of any
                            of its obligations hereunder. This Agreement is binding upon and
                            inures to the benefit of the Parties and their respective permitted
                            successors and assigns.
                        </li>
                        <li>
                            US Government Rights. Each of the Documentation and the software
                            components that constitute the Services is a "commercial item" as
                            that term is defined at 48 C.F.R. § 2.101, consisting of
                            "commercial computer software" and "commercial computer software
                            documentation" as such terms are used in 48 C.F.R. § 12.212.
                            Accordingly, if Customer is an agency of the US Government or any
                            contractor therefor, Customer only receives those rights with
                            respect to the Services and Documentation as are granted to all
                            other end users, in accordance with (a) 48 C.F.R. § 227.7201
                            through 48 C.F.R. § 227.7204, with respect to the Department of
                            Defense and their contractors, or (b) 48 C.F.R. § 12.212, with
                            respect to all other US Government users and their contractors.
                        </li>
                        <li>
                            Equitable Relief. Each Party acknowledges and agrees that a breach
                            or threatened breach by such Party of any of its obligations under
                            Section 6 or, in the case of Customer, Section 2(c), would cause
                            the other Party irreparable harm for which monetary damages would
                            not be an adequate remedy and agrees that, in the event of such
                            breach or threatened breach, the other Party will be entitled to
                            equitable relief, including a restraining order, an injunction,
                            specific performance, and any other relief that may be available
                            from any court, without any requirement to post a bond or other
                            security, or to prove actual damages or that monetary damages are
                            not an adequate remedy. Such remedies are not exclusive and are in
                            addition to all other remedies that may be available at law, in
                            equity, or otherwise.
                        </li>
                        <li>
                            Counterparts. This Agreement may be executed in counterparts, each
                            of which is deemed an original, but all of which together are
                            deemed to be one and the same agreement.
                        </li>
                    </ol>
                </ol>
                <p>
                    IN WITNESS WHEREOF, the Parties hereto have executed this Agreement as of
                    the Effective Date.
                </p>
            </Text>
        </Pane>
    )
}

export default ServiceContract
