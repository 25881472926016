import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { UPSERT_OFFICE, DELETE_OFFICE, GET_OFFICES } from 'graphql/_office-sheet'
import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Heading, toaster } from 'evergreen-ui'
import { Card, CardHeader, Button, FormError } from '@pearly/lib'

import OfficeFields from 'components/_fields/office-fields'
import { useModal } from 'components/modal-provider'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  office?: {
    id: string
    name: string
    address1: string
    address2: string | null
    city: string
    state: Types.State
    zip: string
    phone: string
    email: string | null
  }
}

const OfficeSheet = ({ isShown, setIsShown, office }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [upsertOffice, upsertStatus] = useMutation<Types.UpsertOffice, Types.UpsertOfficeVariables>(UPSERT_OFFICE, {
    update: (cache, { data }) => {
      // return if performing single office update (Apollo automatically updates cache)
      if (office || !data) return

      const cachedData = cache.readQuery<Types.GetOffices>({ query: GET_OFFICES })
      if (cachedData) {
        cache.writeQuery({
          query: GET_OFFICES,
          data: { offices: cachedData.offices.concat([data.upsertOffice]) }
        })
      }
    }
  })

  useEffect(() => {
    if (upsertStatus.error) toaster.danger(`Unable to ${office ? 'update' : 'create'} office`)
    else if (upsertStatus.data && !upsertStatus.loading) {
      setIsShown(false)
      toaster.success(`Office successfully ${office ? 'updated' : 'created'}!`)
    }
  }, [upsertStatus, office, setIsShown])

  const [deleteOffice, deleteStatus] = useMutation<Types.DeleteOffice, Types.DeleteOfficeVariables>(DELETE_OFFICE, {
    variables: { id: office?.id ?? '' },
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetOffices>({ query: GET_OFFICES })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_OFFICES,
          data: { offices: cachedData.offices.filter(office => office.id !== data.deleteOffice.id) }
        })
      }
    }
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete office')
    else if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.danger(`Office successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          office
            ? { ...office }
            : { name: '', address1: '', address2: '', city: '', state: '' as Types.State, zip: '', phone: '' ,email:''}
        }
        onSubmit={({ address2, ...officeFields }) => {
          
          showConfirmDialog({
            body: `Are you sure you want to ${office ? 'update' : 'create'} this office?`,
            confirm: () => {
              upsertOffice({
                variables: {
                  id: office ? office.id : null,
                  address2: address2 ?? null,
                  ...officeFields
                }
              })
            }
          })
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
          address1: Yup.string().required('Address is required'),
          address2: Yup.string().nullable(),
          city: Yup.string().required('City is required'),
          state: Yup.mixed()
            .required('State is required')
            .oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
          zip: Yup.string()
            .required('Zip is required')
            .matches(/^[0-9]\d{4}$/, 'Please enter a valid zip code (5 digits)'),
          phone: Yup.string()
            .required('Phone is required')
            .matches(/^[0-9]\d{9}$/, 'Please enter a valid phone number'),
          email: Yup.string()
            .required('Email is required')
            .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter a valid email address')
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {office ? (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>{office.name}</Heading>
                <Paragraph size={400}>Office Details</Paragraph>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add Office</Heading>
                <Paragraph size={400}>This office will be shown on your landing page</Paragraph>
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <OfficeFields />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {office ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={upsertStatus.loading || upsertStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this office?',
                        confirm: () => {
                          deleteOffice()
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    isLoading={upsertStatus.loading || !!upsertStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={upsertStatus.loading || !!upsertStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                  iconBefore={['fas', 'plus']}
                >
                  Add Office
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default OfficeSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
