import React from 'react'
import styled from 'styled-components/macro'

import { SideSheet, Heading, Pane, Label } from 'evergreen-ui'
import CardHeader from '../card-header'
import { useMediaQueryContext } from '../media-query-provider'
//import TextInputField from '../text-input-field'
import { Formik, Form } from 'formik';

import Button from '../button'

import Card from '../card'
import FormError from '../form-error'
import TextInputField from '../text-input-field'
import Textarea from '../textarea'
// import { Icon} from '@pearly/lib'
import SelectMenu from '../../src/select-menu'
import * as Yup from 'yup'
import moment from 'moment'

export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void
    addPayment: (payment: any) => void
}

const AddPaymentsheet = ({
    isShown,
    setIsShown,
    addPayment
}: Props) => {
    const { isMobile } = useMediaQueryContext()
    // const { loading, error, data } = useQuery<Types.ContractSheet, Types.ContractSheetVariables>(GET_CONTRACT_SHEET, {
    //     variables: { id: planId! },
    //     skip: !isShown || !planId,
    //     client: apolloClient
    // })

    return (
        <SideSheet
            isShown={isShown}
            onCloseComplete={() => setIsShown(false)}
            width={600}
            preventBodyScrolling={true}
            //@ts-ignore
            position={isMobile ? 'bottom' : 'right'}
            shouldCloseOnOverlayClick= {false}
        >

            <SheetLayout>
                <Formik
                    initialValues={{
                        type: '',
                        amount: '',
                        payMonth: '',
                        payDay:'',
                        payYear:'',
                        note :'',
                    }}
                    onSubmit={async (values) => {
                        //await new Promise((r) => setTimeout(r, 500));
                        addPayment(values);
                    }}

                    validationSchema={Yup.object({
                        type: Yup.string().required('Type is required'),
                        amount: Yup.number().required('Amount is required'),
                        payMonth: Yup.number()
                          .required('Payment month is required')
                          .integer('Please provide a valid month')
                          .min(1, 'Please provide a valid month')
                          .max(12, 'Please provide a valid month'),
                        payDay: Yup.number()
                          .required('Payment day is required')
                          .integer('Please provide a valid day')
                          .min(1, 'Please provide a valid day')
                          .max(32, 'Please provide a valid day'),
                        payYear: Yup.number()
                          .required('Payment year is required')
                          .integer('Please provide a valid year')
                          .min(1900, 'Please provide a valid year')
                          .max(moment().year(), 'Please provide a valid year')
                        })}
                >
                    <Form>
                        <SheetLayout>


                            <Pane gridArea="body" background="blueTint" overflow="scroll">
                                <CardHeader gridArea="header">
                                    <Heading size={600}>Add Payment Sheet </Heading>

                                </CardHeader>


                                <Card backgroundColor="white" elevation={0} marginTop='13' margin={16}>
                                    <Label marginBottom={4} alignSelf="flex-start">
                                        Type
                                    </Label>
                                    <SelectMenu
                                        name="type"
                                        options={[
                                            { label: 'Subscription Fee', value: 'SUBS' },
                                            { label: 'Other', value: 'OTHER' },
                                        ]}
                                        height={32}
                                        placeholder='Type'
                                        width={188}
                                        marginBottom={0}
                                    />


                                    <TextInputField name="amount" label="Amount" type="text" placeholder="Amount" height={40} />
                                    <Label alignSelf="flex-start">Payment Date</Label>
                                    <Pane display="flex">
                                        <TextInputField
                                            name="payMonth"
                                            flex={1}
                                            placeholder="MM"
                                            height={40}
                                            marginRight={16}
                                            textAlign="center"
                                            hint="Month"
                                        />
                                        <TextInputField
                                            name="payDay"
                                            flex={1}
                                            placeholder="DD"
                                            height={40}
                                            marginRight={16}
                                            textAlign="center"
                                            hint="Day"
                                        />
                                        <TextInputField name="payYear" flex={2} placeholder="YYYY" textAlign="center" height={40} hint="Year" />
                                    </Pane>
                                    <Label marginBottom={4} alignSelf="flex-start">
                                        Note
                                    </Label> 
                                    <Textarea name="note"  label="Note"  rows="4" placeholder="Enter your Note" marginBottom={0} />
                                </Card>
                            </Pane>
                            <Pane
                                gridArea="footer"
                                elevation={0}
                                padding={16}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"  
                            >

                                <FormError max={2} />
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    height={48}
                                    justifyContent="center"

                                >
                                    Save
                                </Button>
                            </Pane>
                        </SheetLayout>
                    </Form>
                </Formik>

            </SheetLayout>

        </SideSheet>
    )
}


export default AddPaymentsheet


const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
