import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { ADD_FAQ, UPDATE_FAQ, DELETE_FAQ } from 'graphql/_faq'
import * as Types from 'types/graphql'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Heading, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, FormError } from '@pearly/lib'
import FAQFields from 'components/_fields/faq-fields'
import { useModal } from 'components/modal-provider'
import { FAQStatus } from 'types'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  faq?: {
    id: string
    index: number
    question: string
    answer: string
    status: FAQStatus
  }
}

const FAQSheet = ({ isShown, setIsShown, faq }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [createFAQ, createStatus] = useMutation<Types.addFAQ, Types.addFAQVariables>(ADD_FAQ, {
    // refetchQueries:  ['FAQSearch']
  })

  useEffect(() => {
    if (createStatus.error) {
      toaster.danger('Unable to create FAQ')
      createStatus.error = undefined;
    }
    else if (createStatus.data && !createStatus.loading) {
      setIsShown(false)
      toaster.success(`FAQ successfully created!`)
    }
  }, [createStatus, setIsShown])

  const [updateFAQ, updateStatus] = useMutation<Types.updateFAQ, Types.updateFAQVariables>(UPDATE_FAQ, {
    // refetchQueries:  ['FAQSearch']
  })

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update FAQ')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`FAQ successfully updated!`)
    }
  }, [updateStatus, setIsShown])

  const [deleteFAQ, deleteStatus] = useMutation<Types.deleteFAQ, Types.deleteFAQVariables>(DELETE_FAQ, {
    // refetchQueries:  ['FAQSearch']
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete FAQ')
    if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.danger(`FAQ successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          faq
            ? faq
            : {
              index: 0,
              question: '',
              answer: '',
              status: FAQStatus.ACTIVE
            }
        }
        onSubmit={({ ...userFields }) => {
          if (!createStatus.error) {
            showConfirmDialog({
              body: `Are you sure you want to ${faq ? 'update' : 'create'} this FAQ?`,
              confirm: () => {
                if (faq) {
                  updateFAQ({
                    variables: { id: faq.id, ...userFields }
                  })
                } else {
                  createFAQ({
                    variables: {
                      index: userFields.index,
                      question: userFields.question,
                      answer: userFields.answer,
                      status: userFields.status
                    }
                  })
                }
              }
            })
          }
        }}
        validationSchema={Yup.object({
          question: Yup.string().required('Question is required'),
          answer: Yup.string().required('Answer is required'),
          index: Yup.number().required('Index is required'),
          status: Yup.mixed().oneOf([FAQStatus.ACTIVE, FAQStatus.INACTIVE])
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {faq ? (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Update FAQ</Heading>
              </CardHeader>
            ) : (
              <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                <Heading size={600}>Add FAQ</Heading>
              </CardHeader>
            )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                <FAQFields />
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {faq ? (
                <Pane display="flex" justifyContent="space-between">
                  <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={updateStatus.loading || updateStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this FAQ?',
                        confirm: () => {
                          deleteFAQ({ variables: { id: faq.id } })
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>
                  <Button
                    autoFocus
                    isLoading={updateStatus.loading || !!updateStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                <Button
                  isLoading={createStatus.loading || !!createStatus.data}
                  type="submit"
                  appearance="primary"
                  height={48}
                  width="100%"
                  justifyContent="center"
                >
                  Add FAQ
                </Button>
              )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default FAQSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
