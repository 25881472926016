import React, { useState } from 'react'
import moment from 'moment'
import { Table, Text } from 'evergreen-ui'
import Icon from './icon'
import MemberReceipt from './_sheets/member-receipt'

import FailedServices from './_sheets/failed-services'

export type Props = {
  charges: {
    createdAt: any
    type: string | null,
    paymentDate: string | null
    status: string
    amount: number
    plan: string
    last4: string
    cardExp: string | null
    id: string,
    invoiceNumber: string | null,
    paymentErrorMessage: string | null
  }[],
  accountToken: any,
  hostUrl: string,
  updatePracticeInvoice: any,
  isSuperAdmin?: boolean
}


const ServicePaymentHistoryTable = ({ charges, accountToken, hostUrl, updatePracticeInvoice, isSuperAdmin = false }: Props) => {
  const [isReceiptSheetShown, setIsReceiptSheetShown] = useState(false)
  const [paymentId, setPaymentId] = useState('')
  const [isServiceSheetShown, setIsServiceSheetShown] = useState(false)
  const [serviceCharge, setServiceCharge] = useState({})


  // console.warn(accountToken);
  //console.warn('PaymentHistoryTable:');
  //return false;

  const SwitchStatus = (status: any) => {
    switch (status) {
      case 'COMPLETE':
        return "success";
      case 'FAILED':
        return "danger";
      case 'VOIDED':
        return "warning";
      case 'PANDING':
        return "success";
      default:
        return "none";
    }
  }

  let SwitchEffect = (charge: any) => {
    switch (charge?.status) {
      case 'COMPLETE':
        setPaymentId(charge?.id);
        setIsReceiptSheetShown(true);
        break;
      case 'REFUNDED':
        setPaymentId(charge?.id);
        setIsReceiptSheetShown(true);
        break;
      case 'REFUND_COMPLETED':
        setPaymentId(charge?.id);
        setIsReceiptSheetShown(true);
        break;
      case "FAILED":
        setServiceCharge(charge);
        setIsServiceSheetShown(true);
        break;
      default:
        break;
    }
  }

  let SwitchStatusMessage = (status: any) => {
    switch (status) {
      case 'COMPLETE':
        return "Charged";
      case 'FAILED':
        return "Failed";
      case 'REFUNDED':
        return "Refunded"
      case 'REFUND_REQUESTED':
        return "Refund Requested"
      case 'REFUND_COMPLETED':
        return "Refund Completed"
      case 'REFUND_FAILED':
        return "Refund Failed"
      case 'VOIDED':
        return "Voided";
      case 'PENDING':
        return "Pending";
      default:
        return "Pending";
    }

  }


  return (
    <>

      {paymentId != '' ?
        <MemberReceipt
          isShown={isReceiptSheetShown}
          setIsShown={setIsReceiptSheetShown}
          paymentEventId={paymentId}
          getAccountToken={accountToken}
          hostUrl={hostUrl}
          tanentType={'service'}
          updatePracticeInvoice={updatePracticeInvoice}
          isAdmin={isSuperAdmin}
        /> : <></>
      }


      {serviceCharge ?
        <FailedServices
          isShown={isServiceSheetShown}
          setIsShown={setIsServiceSheetShown}
          charge={serviceCharge}
          hostUrl={hostUrl}
          tanentType={'service'}
        /> : <></>
      }

      <Table borderRadius="5px" textAlign="left">
        <Table.Head height={44}>
          <Table.TextHeaderCell flexBasis={88} flexShrink={0} flexGrow={0}>
            Date
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>{`Amount(USD)`}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {charges.map((charge, i) => (
            <Table.Row key={i} height="auto" minHeight={48} paddingY={12}
              cursor="pointer"
              isSelectable
              // intent={//charge.status === 'COMPLETE' ? 'success' : 'danger'}
              intent={SwitchStatus(charge.status)}
              onSelect={() => { SwitchEffect(charge) }}
            >
              <Table.TextCell flexBasis={88} flexShrink={0} flexGrow={0}>
                {console.log(charge)}
                {charge.paymentDate ?? moment(charge.createdAt).format('M/D/YYYY')}
              </Table.TextCell>
              <Table.Cell display="flex">
                <Icon icon={['far', 'dollar-sign']} marginRight={4} fontSize={12} width={16} flexShrink={0} />
                <Text size={300}>
                  {charge.invoiceNumber ? 'Invoice: ' + charge.invoiceNumber.toUpperCase() + ',  ' : ''}
                  {charge.type === 'SUBS' ? 'Service Fee ' : 'Other Fee '}
                  <Text color={SwitchStatus(charge.status)} >{SwitchStatusMessage(charge.status)}</Text> $
                  {charge.amount} - {charge.plan}
                  {charge.last4 != '' ?
                    <i>Card ending in {charge.last4}</i> :
                    <i>Collected offline</i>
                  }
                </Text>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
export default ServicePaymentHistoryTable
