import gql from 'graphql-tag'

const fragments = {
  // also refactor planGridFields
  faqFields: gql`
    fragment faqFields on FAQ {
      id
      index
      question
      answer
      status
    }
  `
}

export const GET_FAQS = gql`
  query GetFaqs($status: FAQStatus) {
    FAQs(status : $status) {
      ...faqFields
    }
  }
  ${fragments.faqFields}
`

export const GET_FAQ_SEARCH = gql`
query FAQSearch($search: String!, $indexOrder: Int!, $first: Int, $after: String) {
    FAQSearch(search: $search, indexOrder: $indexOrder, first: $first, after: $after){
    edges {
          cursor
          node{
            id
            index
            question
            answer
            status
          }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
  }
}
`
export const GET_FAQ_BY_ID = gql`
query singleFAQ($id: ID!) {
  singleFAQ(id: $id){
    ...faqFields
  }
}
${fragments.faqFields}
`

export const ADD_FAQ = gql`
  mutation addFAQ($index: Int!, $question: String!, $answer: String!, $status: FAQStatus!) {
    addFAQ(index: $index, question: $question, answer: $answer, status: $status) {
      ...faqFields
    }
  }
  ${fragments.faqFields}
`

export const UPDATE_FAQ = gql`
  mutation updateFAQ($id: ID!,$index: Int!, $question: String!, $answer: String!, $status: FAQStatus!) {
    updateFAQ( id: $id, index: $index, question: $question, answer: $answer, status: $status) {
      ...faqFields
    }
  }
  ${fragments.faqFields}
`

export const DELETE_FAQ = gql`
  mutation deleteFAQ($id: ID!) {
    deleteFAQ(id: $id) {
      id
    }
  }
`
