import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useQuery, useMutation } from '@apollo/react-hooks'
// Todo: Update Graphql Query for Update Plan and get clearent plan sheet

import { CLEARENT_MERCHANT_SETTING, CLEARENT_CREATE_MERCHANT, CLEARENT_UPDATE_ADDRESS, CLEARENT_UPDATE_MERCHANT, CLEARENT_UPDATE_TAXPAYER } from 'graphql/_clearent-onboarding'

import * as Types from 'types'

import { SideSheet, Pane, Tablist, Tab, Text, Heading, toaster } from 'evergreen-ui'
import { CardHeader, Icon, Spinner } from '@pearly/lib'
import { useModal } from 'components/modal-provider'

import Clearent from 'components/onboarding-clearent'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  planId: string
}

const steps = [
  { path: 'merchant', component: Clearent.Merchant },
  { path: 'physical address', component: Clearent.Address },
  { path: 'mailing address', component: Clearent.MailingAddress },
  { path: 'bank', component: Clearent.Bank },
  { path: 'Legal', component: Clearent.Legal },
  { path: 'Owner', component: Clearent.Owner },
]

const ClearentSheet = ({ isShown, setIsShown }: Props) => {
  const showConfirmDialog = useModal('confirm')

  const [currentPath, setCurrentPath] = useState('merchant')
  const CurrentView = steps.find(step => step.path === currentPath)!.component

  const [dirty, setDirty] = useState(false)

  const { loading, error, data } = useQuery<Types.clearentMerchantSetting>(CLEARENT_MERCHANT_SETTING)

  const [createMerchant, createMerchantStatus] = useMutation<Types.clearentCreateMerchant, Types.clearentCreateMerchantVariables>(CLEARENT_CREATE_MERCHANT)

  useEffect(() => {
    if (createMerchantStatus.error) {
      toaster.danger(`Unable to create merchant`)
    }
    else if (createMerchantStatus.data && !createMerchantStatus.loading) {
      toaster.success('Merchant successfully created!')
      setCurrentPath('physical address');
      setDirty(false)
    }
  }, [createMerchantStatus])

  const [updateMerchant, updateMerchantStatus] = useMutation<Types.clearentUpdateMerchant, Types.clearentUpdateMerchantVariables>(CLEARENT_UPDATE_MERCHANT)

  useEffect(() => {
    if (updateMerchantStatus.error) {
      toaster.danger(`Unable to update Merchant`)
    }
    else if (updateMerchantStatus.data && !updateMerchantStatus.loading) {
      toaster.success('Merchant successfully updated!')
      setCurrentPath('physical address');
      setDirty(false)
    }
  }, [updateMerchantStatus])

  const [updateAddress, updateAddressStatus] = useMutation<Types.clearentUpdateAddress, Types.clearentUpdateAddressVariables>(CLEARENT_UPDATE_ADDRESS);

  useEffect(() => {
    if (updateAddressStatus.error) {
      toaster.danger(`Unable to update Address`)
    }
    else if (updateAddressStatus.data && !updateAddressStatus.loading) {
      toaster.success('Address successfully updated!')
      if (updateAddressStatus.data.clearentUpdateAddress.addressType === 1) {
        setCurrentPath('mailing address');
      }
      if (updateAddressStatus.data.clearentUpdateAddress.addressType === 2) {
        setCurrentPath('bank');
      }
      setDirty(false)
    }
  }, [updateAddressStatus])

  const [updateTaxPayer, updateTaxPayerStatus] = useMutation<Types.clearentUpdateTaxPayer, Types.clearentUpdateTaxPayerVariables>(CLEARENT_UPDATE_TAXPAYER);

  useEffect(() => {
    if (updateTaxPayerStatus.error) {
      toaster.danger(`Unable to update Address`)
    }
    else if (updateTaxPayerStatus.data && !updateTaxPayerStatus.loading) {
      toaster.success('Address successfully updated!')
      setCurrentPath('mailing address');
      setDirty(false)
    }
  }, [updateTaxPayerStatus])



  // useEffect(() => {
  //   if (merchantStatus.error) toaster.danger(`Unable to update plan`)
  //   else if (merchantStatus.data && !merchantStatus.loading) {
  //     toaster.success('Plan successfully updated!')
  //     setDirty(false)
  //   }
  // }, [merchantStatus])

  //


  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
      }}
      shouldCloseOnOverlayClick={false}
    >


      {loading || error || !data?.clearentMerchantSetting ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (

          <SheetLayout>
            <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
              <Pane
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={16}
                paddingRight={24}
                borderBottom="muted"
              >
                <Pane display="flex" alignItems="center">
                  <Icon icon={['fad', 'truck-loading']} size="2x" color="selected" paddingLeft={4} />
                  <Pane marginLeft={16}>
                    <Heading size={600}>Clearent Onboarding</Heading>
                    <Text size={400}>Membership Onboarding</Text>
                  </Pane>
                </Pane>
              </Pane>
              <Pane display="flex" padding={8}>
                <Tablist>
                  {steps.map(step => {
                    const { path } = step
                    return (
                      <Tab
                        key={path}
                        textTransform="uppercase"
                        isSelected={currentPath === path}
                        onSelect={() => {
                          if (dirty) {
                            showConfirmDialog({
                              title: 'Unsaved Changes',
                              body: 'Your plan has unsaved changes that will be lost.  Would you like to continue?',
                              intent: 'danger',
                              confirmLabel: 'Continue Without Saving',
                              confirm: () => {
                                setCurrentPath(path)
                                setDirty(false)
                              }
                            })
                          } else {
                            setCurrentPath(path)
                          }
                        }}
                      >
                        {path}
                      </Tab>
                    )
                  })}
                </Tablist>
              </Pane>
            </CardHeader>
            <Pane gridArea="body" overflow="hidden">
              <CurrentView merchantSetting={data}
                createMerchant={[createMerchant, createMerchantStatus]}
                updateMerchant={[updateMerchant, updateMerchantStatus]}
                updateAddress={[updateAddress, updateAddressStatus]}
                udpateTaxPayer = {[updateTaxPayer, updateTaxPayerStatus]}
                dirtyState={[dirty, setDirty]} />
            </Pane>
          </SheetLayout>
        )}
    </SideSheet>
  )
}

export default ClearentSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`
