import React, { useState, useEffect } from 'react'
import moment from 'moment'

import { ApolloProvider, useMutation } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-client'

import { Heading, Pane, Text, PaneProps, toaster, CardProps } from 'evergreen-ui'

import Card from './card'
import CardHeader from './card-header'
import Button from './button'
import Spinner from './spinner'

import { useQuery } from '@apollo/react-hooks'
import { GET_DEPENDENT_CARD_LIST } from './graphql/_dependent-card-list'
import { CHANGE_CONTACT_PLAN_SUBSCRIPTION_PLAN, CLONE_CONTACT_PLAN_SUBSCRIPTION, UPDATE_CONTACT_SUBSCRIPTION, UPDATE_START_END_DATE} from './graphql/_view-member-sheet'
import * as Types from './types'

import ContractSheet from './_sheets/contract-sheet'
import ViewMemberSheet from './_sheets/view-member-sheet'
import EnrollMemberSheet from './_sheets/enroll-member-sheet'

import { isJson } from './utility/transforms'
import { effectiveDateRangeInDateTime } from './utility/formatters'
// import ConfirmDialog from '../_dialogs/confirm-dialog'
import DependentsIllustration from './assets/img/dependents'
import { memberAffectedQueries } from './utility/member-affected-queries'
// import { getShownPrice } from './utility/transforms'
import UpdatePlanDateSheet from './_sheets/update-plan-date-sheet'
import UpdatePlanSheet from './_sheets/update-plan-sheet'
import ConfirmDialog from './_dialogs/confirm-dialog'
import { PlanTerm } from './types'
import CardUpcomingRenewal from './card-upcoming-renewal'
import SelectPlanSheet from './_sheets/select-plan-sheet'

type ContractProps = { planId: string; planTerm: Types.PlanTerm; cancelled: boolean; effectiveDate?: Date, endDate?: Date }

export interface Props extends PaneProps {
  contactId: string
  isAdmin?: boolean
  inSheet?: boolean
  apolloClient?: ApolloClient<any>
  accountToken?: any
}

const DependentCardList = ({ contactId, isAdmin = true, inSheet = false, apolloClient,accountToken, ...props }: Props) => {
  const [isEnrollSheetShown, setIsEnrollSheetShown] = useState(false)
  // const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)
  // const [confirmFunction, setConfirmFunction] = useState({} as any)
  const [isContractSheetShown, setIsContractSheetShown] = useState(false)
  const [contractProps, setContractProps] = useState<ContractProps>({} as ContractProps)

  const showContractSheet = (contractProps: ContractProps) => {
    setContractProps(contractProps)
    setIsContractSheetShown(true)
  }

  const [isDependentSheetShown, setIsDependentSheetShown] = useState(false)
  const [selectedDependentId, setSelectedDependentId] = useState('')

  const showDependentSheet = (dependentId: string) => {
    setSelectedDependentId(dependentId)
    setIsDependentSheetShown(true)
  }

  const { loading, error, data } = useQuery<Types.DependentCardList, Types.DependentCardListVariables>(
    GET_DEPENDENT_CARD_LIST,
    {
      variables: {
        contactId
      },
      context: { secure: true },
      client: apolloClient
    }
  )

  return (
    <ApolloProvider client={apolloClient as ApolloClient<any>}>
      <EnrollMemberSheet isShown={isEnrollSheetShown} setIsShown={setIsEnrollSheetShown}
                 parentId={contactId} memberApp={!isAdmin} 
      />
      {/* <ConfirmDialog
        isShown={isConfirmDialogShown}
        setIsShown={setIsConfirmDialogShown}
        confirm={confirmFunction}
        body="Are you sure you want to cancel this plan?"
        intent="danger"
      /> */}
      <ViewMemberSheet
        isShown={isDependentSheetShown}
        setIsShown={setIsDependentSheetShown}
        memberId={selectedDependentId}
        memberApp={!isAdmin}
        isAdmin={isAdmin}
        accountToken={accountToken}
        tanentType={isAdmin?'provider':'member'}
      />

      <ContractSheet
        isShown={isContractSheetShown}
        setIsShown={setIsContractSheetShown}
        planId={contractProps.planId}
        planTerm={contractProps.planTerm}
        cancelled={contractProps.cancelled}
        effectiveDate={contractProps.effectiveDate}
        endDate={contractProps.endDate}
        apolloClient={apolloClient}
        dependent={true}
      />

      <Pane padding={16} {...props} display="flex" flexDirection="column" alignItems="center">
        {loading || error || !data ? (
          <Pane display="flex" alignItems="center" height="100%">
            <Spinner />
          </Pane>
        ) : data.dependents.length ? (
          <>
            {data.dependents.map((dependent, i) => {
              const secureContact =
                dependent.secure && isJson(dependent.secure) && (JSON.parse(dependent.secure) as Types.SecureContact)
              if (!secureContact) throw Error('Unable to retrieve secure contact information')
              return (
                <DependentPlanCard
                  isAdmin={isAdmin}
                  key={dependent.id}
                  inSheet={inSheet}
                  dependent={dependent}
                  name={`${secureContact.firstName} ${secureContact.lastName}`}
                  showContractSheet={showContractSheet}
                  showDependentSheet={showDependentSheet}
                  marginBottom={i < data.dependents.length - 1 ? 16 : 0}
                />
              )
            })}
            {inSheet && (
              <Button
                marginTop={16}
                iconBefore={['fas', 'user-plus']}
                appearance="primary"
                onClick={() => setIsEnrollSheetShown(true)}
              >
                Enroll Dependent
              </Button>
            )}
          </>
        ) : (
          <Pane
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            marginTop={36}
            marginBottom={32}
          >
            <Pane height="120px">
              <DependentsIllustration />
            </Pane>

            <Text marginTop={24} marginBottom={6} size={500}>
              No dependents have been enrolled...
            </Text>
            <Button size={500} appearance="minimal" onClick={() => setIsEnrollSheetShown(true)}>
              Enroll New Dependent
            </Button>
          </Pane>
        )}
      </Pane>
    </ApolloProvider>
  )
}

export default DependentCardList

interface DependentCardProps extends CardProps {
  isAdmin: boolean
  name: string
  dependent: Types.DependentCardList_dependents
  inSheet: boolean
  apolloClient?: ApolloClient<any>
  showContractSheet: (contractProps: ContractProps) => void
  showDependentSheet: (dependentId: string) => void
}

const DependentPlanCard = ({
  isAdmin,
  name,
  dependent,
  inSheet,
  apolloClient,
  showContractSheet,
  showDependentSheet,
  ...props
}: DependentCardProps) => {
  if (!(dependent.subscriptions)) throw Error('Dependent data incomplete')
  const {
    subscriptions
  } = dependent

  const [isAuthorizedDialogShown, setIsAuthorizedDialogShown] = useState(false)
  const [isAcceptDialogShown, setIsAcceptDialogShown] = useState(false)
  const [isUpdatePlanDateShown, setIsUpdatePlanDateShown] = useState(false)
  const [isReactivatePlanShown, setIsReactivatePlanShown] = useState(false)
  const [isPlanReactivated, SetIsPlanReactivated] = useState(false)


  // TODO - get data from backend.
  const currentSub = subscriptions?.find(p => p)
  const [subscription, setSubscription] = useState({
    cancelled: currentSub?.isCancelled,
    active: currentSub?.isEnabled,
    endDate: currentSub?.endDate,
    effectiveDate: currentSub?.startDate,
    status: currentSub?.status
  })
  const plan=currentSub?.plan
  const planTerm = currentSub?.planTerm??dependent.planTerm

  const annualDependentPrice = plan?.annualDependentPrice ?? 0
  const monthlyDependentPrice = plan?.monthlyDependentPrice ?? 0
  const dependentDiscount = plan?.dependentDiscount ?? 0

  const [update, updateStatus] = useMutation<Types.updateStartEndDate, Types.updateStartEndDateVariables>(UPDATE_START_END_DATE,
    {
      refetchQueries: memberAffectedQueries
    })

  const [updateSubscription, updateSubscriptionStatus] = useMutation<
    Types.UpdateContactSubscription,
    Types.UpdateContactSubscriptionVariables
  >(UPDATE_CONTACT_SUBSCRIPTION, {
    refetchQueries: memberAffectedQueries
  })

  const [cloneContactSubscription, cloneContactSubscriptionStatus] = useMutation<Types.cloneContactPlanSubscription, Types.cloneContactPlanSubscriptionVariables>(CLONE_CONTACT_PLAN_SUBSCRIPTION,
    {
      refetchQueries: [...memberAffectedQueries, 'GetContactPlanSubscriptionsFull']
    })
  
  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update Date')
    else if (updateStatus.data && !updateStatus.loading) {
      toaster.success(`Date updated!`)
      const updatedData = { ...subscription }
      updatedData.effectiveDate = updateStatus.data?.updateStartEndDate.startDate;
      updatedData.endDate = updateStatus.data?.updateStartEndDate.endDate;
      setSubscription(updatedData)
      setIsUpdatePlanDateShown(false)
    }
  }, [updateStatus])


  useEffect(() => {
    if (updateSubscriptionStatus.error) toaster.danger('Unable to update dependent membership')
    else if (updateSubscriptionStatus.data && !updateSubscriptionStatus.loading) {
      const sub = updateSubscriptionStatus.data.updateContactSubscription
      const updatedData = { ...subscription }
      updatedData.active = sub?.isEnabled
      updatedData.cancelled = sub?.isCancelled
      updatedData.status = sub?.status
      setSubscription(updatedData)
      if (sub?.isCancelled) {
        toaster.danger(`Dependent membership has been cancelled`)
      } else {
        toaster.success(`Dependent membership has been activated!`)
      }
    }
  }, [updateSubscriptionStatus])
  const [changeSubscriptionPlan, changeSubscriptionPlanStatus] = useMutation<Types.changeContactPlanSubscriptionPlan, Types.changeContactPlanSubscriptionPlanVariables>(CHANGE_CONTACT_PLAN_SUBSCRIPTION_PLAN,
    {
      refetchQueries: memberAffectedQueries
    });
  const [isUpdatePlanShown, setIsUpdatePlanShown] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(plan)

  useEffect(() => {
    if (changeSubscriptionPlanStatus.error) toaster.danger('Unable to change plan')
    else if (changeSubscriptionPlanStatus.data && !changeSubscriptionPlanStatus.loading) {
      toaster.success(`Plan updated!`)
      const updatedPlan = changeSubscriptionPlanStatus.data?.changeContactPlanSubscriptionPlan?.plan
      if (updatedPlan) {
        setCurrentPlan(updatedPlan)
        // if(dependent?.planTerm){
        //   price =  getShownPrice(dependent?.planTerm, true, currentPlan)
        // }
      }
      setIsUpdatePlanShown(false);
    }
  }, [changeSubscriptionPlanStatus])

  useEffect(() => {
    if (cloneContactSubscriptionStatus.error) toaster.danger('Unable to reactivate subscription')
    else if (cloneContactSubscriptionStatus.data && !cloneContactSubscriptionStatus.loading) {
      toaster.success(`Subscription reactivated!`);
      setIsReactivatePlanShown(false);
      SetIsPlanReactivated(true);
    }
  }, [cloneContactSubscriptionStatus])


  const updatePlan = (planId: string, term: PlanTerm) => {
    if (currentSub?.id && planTerm) {
      changeSubscriptionPlan({
        variables: {
          id: currentSub?.id,
          planId: planId,
          planTerm: term
        }
      })
    }
    setIsUpdatePlanShown(false)
  }
  const isEmpty=(s:string|null|undefined): boolean => {return (s ==null || s==undefined || s.length==0) }

  const activatePlan = () => {
    const subs = currentSub;
    if (subs?.collectingMethod == "MANUAL" || !isEmpty(subs?.creditCard_type)) {
      updateSubscription({ variables: { id: subs!.id, reactivate: true } })
    } else {
      toaster.danger('Credit card must be on file before activating.')
    }
  }
  const authorizedPlan = () => {
    const subs = currentSub;
    if (subs?.collectingMethod == "MANUAL" || !isEmpty(subs?.creditCard_type)) {
      updateSubscription({ variables: { id: subs!.id, reactivate: true, accept: true } })
    } else {
      toaster.danger('Credit card must be on file before activating.')
    }
  }
  const acceptPlan = () => {
    const subs = currentSub;
    if (subs?.collectingMethod == "MANUAL" || !isEmpty(subs?.creditCard_type)) {
      updateSubscription({ variables: { id: subs!.id, accept: true } })
    } else {
      toaster.danger('Credit card must be on file before accepting.')
    }
  }
  const secureContact = (dependent.secure && isJson(dependent.secure))?(JSON.parse(dependent.secure) as Types.SecureContact):null

  const reactivatePlan = (planId: string, term: PlanTerm) => {
    if (currentSub?.id) {      
      cloneContactSubscription({
        variables: {
          id: currentSub?.id,
          planId: planId,
          planTerm: term,
          email: secureContact?.email,
          firstName: secureContact?.firstName,
          lastName: secureContact?.lastName,
          phoneNumber: secureContact?.phone
        }
      }) 
    }
  }

  return (
    <>
      <UpdatePlanDateSheet
        isShown={isUpdatePlanDateShown}
        setIsShown={setIsUpdatePlanDateShown}
        subsId={currentSub?.id ?? ''}
        planName={currentPlan?.name??''}
        isDependent={true}
        memberName={name}
        cancelled={subscription.cancelled}
        effectiveDate={subscription.effectiveDate}
        endDate={subscription.endDate}
        udpateDate={[update, updateStatus]}
      />

      <UpdatePlanSheet
        isShown={isUpdatePlanShown}
        setIsShown={setIsUpdatePlanShown}
        currentPlanId={currentPlan?.id??''}
        currentTerm={planTerm}
        updatePlan={updatePlan}
      />
      <ConfirmDialog
        isShown={isAuthorizedDialogShown}
        setIsShown={setIsAuthorizedDialogShown}
        confirm={() => authorizedPlan()}
        body="You are about to manually activate this patient’s membership enrollment. By confirming this action, the practice acknowledges a signed digital agreement may not be completed. The Practice accepts responsibility of all agreement terms by accepting enrollment on behalf of the patient."
        intent="danger"
      />
      <ConfirmDialog
        isShown={isAcceptDialogShown}
        setIsShown={setIsAcceptDialogShown}
        confirm={() => acceptPlan()}
        body="By confirming this action, I acknowledge that I have read and accept the contract for this membership plan."
        intent="danger"
      />
      <SelectPlanSheet
        title={'Reactivate Plan'}
        isShown={isReactivatePlanShown}
        setIsShown={setIsReactivatePlanShown}
        currentPlanId={currentPlan?.id??''}
        currentTerm={planTerm}
        currentPlanName={currentPlan?.name??'No plan'}
        onSelectPlan={reactivatePlan}
      />


      <Card elevation={inSheet ? 0 : 1} padding={0} width="100%" {...props}>
        <CardHeader withButton>
          <Heading size={500}>{name}</Heading>

          <Button
            iconBefore={['fas', 'files-medical']}
            onClick={() =>
              showContractSheet({
                planId: plan?.id??'',
                planTerm: planTerm!,
                cancelled: subscription.cancelled ?? false,
                effectiveDate: subscription.effectiveDate,
                endDate: subscription.endDate
              })
            }
          >
            View Contract
        </Button>
        </CardHeader>
        {isPlanReactivated?(<Pane padding={24}>A new subscription is created.</Pane>)
                :
          <Pane padding={16} display="flex" flexDirection="column">
          <Pane display="grid" gridTemplateColumns="auto 1fr" gridRowGap={12} gridColumnGap={40} marginBottom={16}>
            <Text size={400} textTransform="uppercase" color="selected">
              Plan:
          </Text>
            <Text size={500}>{plan?.name}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              ID:
          </Text>
            <Text size={500}>{dependent.code}</Text>

            <Text size={400} textTransform="uppercase" color="selected">
              Effective Dates:
          </Text>
            <Text size={500}>{effectiveDateRangeInDateTime(subscription.effectiveDate, subscription.endDate)}</Text>

            <Text size={400} textTransform="uppercase" color="selected">
              RENEWAL:
          </Text>
            <Text size={500}>{subscription.cancelled ? 'Cancelled' : 'Annual'}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              {subscription.cancelled ? 'Termination' : 'Renewal'} Date:
          </Text>
            <Text size={500}>{moment.utc(subscription.endDate).add(1, "days").format('M/D/YYYY')}</Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Billing Term:
           </Text>
            <Text size={500} marginBottom={8}>
                  {planTerm ?? ''}
            </Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Subscription:
           </Text>
            <Text size={500}>
              {planTerm === Types.PlanTerm.ANNUAL
                ? `$${annualDependentPrice} / year`
                : `$${monthlyDependentPrice} / month`}
            </Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Collection Method:
            </Text>
            <Text size={500}>
              {currentSub ? currentSub.collectingMethod === Types.collectingMethod.AUTO
                ? `Automatic`
                : `Manual` : ''}
            </Text>
            <Text size={400} textTransform="uppercase" color="selected">
              Status:
          </Text>
            <Text size={500}>{subscription.status}</Text>
            {plan?.dependentDiscount && (
              <>
                <Text size={400} textTransform="uppercase" color="selected">
                  Discount:
              </Text>
                <Text size={500}>{dependentDiscount}%</Text>
              </>
            )}
          </Pane>
          <Pane display="flex" alignSelf="center">
            {subscription.cancelled ? (
              isAdmin ?
                <Button
                  isLoading={updateSubscriptionStatus.loading}
                  appearance="minimal"
                  intent="success"
                  onClick={() => setIsReactivatePlanShown(true)}
                  >
                  Reactivate
              </Button> : (<></>)
            ) : (
              <Button
                isLoading={updateSubscriptionStatus.loading}
                appearance="minimal"
                intent="danger"
                onClick={() => updateSubscription({ variables: { id: currentSub!.id, cancel: true } })}
              >
                Cancel
              </Button>
            )}
            {(subscription.status === Types.PlanSubscriptionStatus.PENDING && isAdmin) ?
              (
                <Button
                  appearance="minimal"
                  isLoading={updateSubscriptionStatus.loading}
                  onClick={() => { activatePlan() }}
                >
                  Activate
                </Button>
              ) : (<></>)}
            {(subscription.status === Types.PlanSubscriptionStatus.PROPOSED) ?
              (
                isAdmin ?
                  <Button
                    appearance="minimal"
                    isLoading={updateSubscriptionStatus.loading}
                    onClick={() => { setIsAuthorizedDialogShown(true) }}
                  >
                    Authorized
                  </Button> :
                  <Button
                    appearance="minimal"
                    isLoading={updateSubscriptionStatus.loading}
                    onClick={() => { acceptPlan() }}
                  >
                    Accept
                  </Button>
              ) : (<></>)}
            <Button appearance="minimal" onClick={() => showDependentSheet(dependent.id)}>
              Edit Profile
            </Button>
            {isAdmin ?
              (<Button appearance="minimal" onClick={() => setIsUpdatePlanDateShown(true)}>
                Change Date
              </Button>) : (<></>)
            }
            {
              (isAdmin && subscription.status == Types.PlanSubscriptionStatus.CANCELLED) ? (
                <Button marginLeft={0} onClick={() => setIsUpdatePlanShown(true)}>
                  Change Plan
                </Button> 
                ):<></> 
            }
          </Pane>
        </Pane>
        }  
        {currentSub?.renewalSubscriptionId?
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} >
              <CardHeader justifyContent="space-between">
                <Heading size={500}>Upcoming Renewal</Heading>
              </CardHeader>
              <CardUpcomingRenewal renewalSubscriptionId={currentSub.renewalSubscriptionId} apolloClient={apolloClient} dependent={true} isAdmin={isAdmin}></CardUpcomingRenewal>
          </Card>:<></> 
        }
      </Card>
    </>
  )
}
