import React from 'react'
import styled from 'styled-components/macro'

import * as Types from 'types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading, Spinner, Label, TextInputField, TextInput } from 'evergreen-ui'
import { Button, Card, CardHeader, TripleTextInput } from '@pearly/lib'
import { useModal } from 'components/modal-provider'
import { useQuery } from '@apollo/react-hooks'
import { CLEARENT_ADDRESS } from 'graphql/_clearent-onboarding'

export type Props = {
  merchantSetting: Types.clearentMerchantSetting
  createMerchant: [Types.ClearentCreateMerchantProp, Types.ClearentCreateMerchantStatusProp]
  updateMerchant: [Types.ClearentUpdateMerchantProp, Types.ClearentUpdateMerchantStatusProp]
  updateAddress: [Types.ClearentUpdateAddressProp, Types.ClearentUpdateAddressStatusProp]
  udpateTaxPayer: [Types.ClearentUpdateTaxPayerProp, Types.ClearentUpdateTaxPayerStatusProp],
  dirtyState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

const ClearentMailingAddress = ({ //merchantSetting,
  //createMerchant: [createMerchant, createMerchantStatus],
  //updateMerchant: [updateMerchant, updateMerchantStatus],
  updateAddress: [updateAddress],
  //udpateTaxPayer: [updateTaxPayer, updateTaxPayerStatus],
  dirtyState: [dirty, setDirty] }: Props) => {
  const showConfirmDialog = useModal('confirm')

  //const [isContractSheetShown, setIsContractSheetShown] = useState(false)

  const { loading, error, data } = useQuery<Types.clearentAddress, Types.clearentAddressVariables>(CLEARENT_ADDRESS,
    {
      variables: {
        addressType: 2
      }
    }
  )

  const initialValues = {
    AddressLine1: data?.clearentAddress.line1 ?? '',
    AddressLine2: data?.clearentAddress.line2 ?? '',
    AddressLine3: data?.clearentAddress.line3 ?? '',
    City: data?.clearentAddress.city ?? '',
    State: data?.clearentAddress.stateCode ?? '',
    ZipCode: data?.clearentAddress.zip ?? '',
    CountryCode: ""
  }

  return (
    <>
      {loading || error || !data ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
          <Formik
            initialValues={initialValues}
            validate={args => {
              if (!dirty && args !== initialValues) setDirty(true)
            }}
            onSubmit={args => {
              showConfirmDialog({
                body: 'Are you sure you want to save Address?',
                confirm: () => {
                  updateAddress({
                    variables: {
                      addressType: 2,
                      line1: args.AddressLine1 ?? '',
                      line2: args.AddressLine2,
                      line3: args.AddressLine3,
                      city: args.City ?? '',
                      stateCode: args.State ?? '',
                      zip: args.ZipCode,
                      countryCode: 840,
                    }
                  })
                }
              })
            }}
            validationSchema={Yup.object({
              AddressLine1: Yup.string().when('AddressLine2', {
                is: val => val,
                then: Yup.string().required('Please submit a complete address')
              }),
              AddressLine2: Yup.string(),
              AddressLine3: Yup.string(),
              City: Yup.string().required('City is required'),
              State: Yup.mixed().oneOf(Object.values(Types.State), 'Please submit a valid state (abbreviation)'),
              ZipCode: Yup.string().matches(/^[0-9]\d{4}$/, 'Please enter a valid zip code (5 digits)'),

            })}
          ><Form style={{ height: '100%', width: '100%' }}>
              <SheetBodyLayout>
                <Pane gridArea="body" background="blueTint" overflow="scroll">
                  <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
                    <CardHeader withButton>
                      <Heading size={500}>Mailing Address</Heading>
                        <Button onClick={() => {}}>
                          Same as Physical Address
                        </Button>
                    </CardHeader>
                    <Pane marginTop={8} marginX={12} >
                      <Label marginBottom={8} alignSelf="flex-start">
                        Address
                      </Label>
                      <TripleTextInput name="AddressLine1" half="top" width="100%" type="text" placeholder="Address Line 1" paddingLeft={20} />
                      <TripleTextInput name="AddressLine2" half="mid" width="100%" type="text" placeholder="Address Line 2" />
                      <TripleTextInput name="AddressLine3" half="bottom" width="100%" type="text" placeholder="Address Line 3" />
                    </Pane>

                    <Pane display="flex" marginTop={0} marginX={12} >
                      <TextInputField name="City" label="City" placeholder="" height={40} width="100%" />
                    </Pane>

                    <Pane display="flex" justifyContent="space-between" marginTop={0} marginX={12} >
                      <TextInput name="State" type="text" placeholder="CA" marginRight={8} width="100%" />
                      <TextInput name="ZipCode" type="text" placeholder="93101" marginLeft={8} width="100%" />
                    </Pane>
                    <Pane display="flex" marginTop={0} marginBottom={20} marginX={12} >
                      <Button
                        type="submit"
                        justifyContent="center"
                        appearance="primary"
                        marginRight={8}
                        marginLeft={0}
                      >
                        Save Address
                    </Button>
                      <Button
                        type="button"
                        justifyContent="center"
                      >
                        Cancel
                    </Button>
                    </Pane>
                  </Card>

                </Pane>


              </SheetBodyLayout>
            </Form>
          </Formik>
        )}
    </>
  )
}

export default ClearentMailingAddress

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
