import * as Types from './types'
import moment from 'moment'
import { ApolloClient } from 'apollo-client'

import React, { useEffect, useState } from 'react'
import { GET_CONTACT_PLAN_SUBSCRIPTION_FULL, UPDATE_CONTACT_SUBSCRIPTION } from './graphql/_view-member-sheet'
import { useMutation, useQuery } from '@apollo/react-hooks'

import { Pane, Text, toaster } from 'evergreen-ui'
import Button from './button'
import ContractSheet from './_sheets/contract-sheet'
import ConfirmDialog from './_dialogs/confirm-dialog'
import { PlanSubscriptionStatus } from './types'

type ContractProps = { planId: string; planTerm: Types.PlanTerm; cancelled: boolean; effectiveDate?: Date, endDate?: Date }

export type Props = {
    contact?: Types.ViewMemberSheet_contact
    renewalSubscriptionId: string
    isAdmin?: boolean
    apolloClient?: ApolloClient<any>
    dependent: boolean
    setIsRenewPlanShown?: any,
    resendRenewalEmail?: any,
}

const CardUpcomingRenewal = ({ renewalSubscriptionId, apolloClient, dependent, isAdmin, setIsRenewPlanShown, resendRenewalEmail }: Props) => {

    const { data: renewalSubData } = useQuery<Types.GetContactPlanSubscriptionsFull, Types.GetContactPlanSubscriptionsFullVariables>(GET_CONTACT_PLAN_SUBSCRIPTION_FULL, {
        variables: {
            id: renewalSubscriptionId ?? ''
        },
        skip: !renewalSubscriptionId
    })

    const [isContractSheetShown, setIsContractSheetShown] = useState(false)
    const [contractProps, setContractProps] = useState<ContractProps>({} as ContractProps)
    const [isAuthorizedDialogShown, setIsAuthorizedDialogShown] = useState(false)
    const [isResendEmailDialogShown, setIsResendEmailDialogShown] = useState(false)
    const [isCancelDialogShown, setIsCancelDialogShown] = useState(false)

    const showContractSheet = (contractProps: ContractProps) => {
        setContractProps(contractProps)
        setIsContractSheetShown(true)
    }

    const [updateSubscription, updateSubscriptionStatus] = useMutation<
        Types.UpdateContactSubscription,
        Types.UpdateContactSubscriptionVariables
    >(UPDATE_CONTACT_SUBSCRIPTION, {
        refetchQueries: ['GetContactPlanSubscriptionsFull']
    }
    )

    useEffect(() => {
        if (updateSubscriptionStatus.error) toaster.danger('Unable to update membership')
        else if (updateSubscriptionStatus.data && !updateSubscriptionStatus.loading) {
            const sub = updateSubscriptionStatus.data.updateContactSubscription
            if (!sub) toaster.danger('Unable to update membership')
            toaster.success(`Plan membership has been updated!`)
        }
    }, [updateSubscriptionStatus])

    const cancelPlan = (subscriptionId: string | null) => {
        if (subscriptionId)
            updateSubscription({ variables: { id: subscriptionId, cancel: true } })
    }


    const acceptPlan = (subscriptionId: string | null) => {
        if (subscriptionId)
            updateSubscription({ variables: { id: subscriptionId, accept: true } })
    }

    const authorizeAccept = (subscriptionId: string | null) => {
        if (subscriptionId)
            updateSubscription({ variables: { id: subscriptionId, reactivate: true, accept: true } })
    }

    const showCancelButton = (sub: any) => isAdmin && !sub?.cancelled
    const showAuthorizedButton = (sub: any) => isAdmin && sub?.status === 'PROPOSED' && !sub?.cancelled

    return (
        <> {
            renewalSubData?.getContactPlanSubscriptions && renewalSubData?.getContactPlanSubscriptions?.status !== PlanSubscriptionStatus.CANCELLED ? (
                <>
                    {/* Cancel Plan Confirm */}
                    <ConfirmDialog
                        isShown={isCancelDialogShown}
                        setIsShown={setIsCancelDialogShown}
                        confirm={() => cancelPlan(renewalSubData?.getContactPlanSubscriptions?.id ?? null)}
                        body="Are you sure you want to cancel this plan?"
                        intent="danger"
                    />

                    <ConfirmDialog
                        isShown={isAuthorizedDialogShown}
                        setIsShown={setIsAuthorizedDialogShown}
                        confirm={() => authorizeAccept(renewalSubData?.getContactPlanSubscriptions?.id ?? null)}
                        body="You are about to manually activate this patient’s membership enrollment. By confirming this action, the practice acknowledges a signed digital agreement may not be completed. The Practice accepts responsibility of all agreement terms by accepting enrollment on behalf of the patient."
                        intent="danger"
                    />

                    <ConfirmDialog
                        isShown={isResendEmailDialogShown}
                        setIsShown={setIsResendEmailDialogShown}
                        confirm={() => resendRenewalEmail()}
                        body="You Want to send renewal email again?"
                        intent="danger"
                    />

                    <ContractSheet
                        isShown={isContractSheetShown}
                        setIsShown={setIsContractSheetShown}
                        planId={contractProps.planId}
                        planTerm={contractProps.planTerm}
                        cancelled={contractProps.cancelled}
                        effectiveDate={contractProps.effectiveDate}
                        endDate={contractProps.endDate}
                        apolloClient={apolloClient}
                        dependent={dependent}
                    />
                    <Pane display="flex" padding={16} justifyContent="flex-start" flexDirection="column">
                        <Text overflowWarp="break-word" wordWrap="break-word" size={500} marginBottom={8}>
                            <strong>Plan: </strong>
                            {renewalSubData?.getContactPlanSubscriptions?.plan?.name}{' '}
                            <Text size={500} color="muted">
                                ({renewalSubData?.getContactPlanSubscriptions?.status})
                            </Text>
                        </Text>
                        <Text size={500} marginBottom={8}>
                            <strong>Billing Term: </strong>
                            {renewalSubData?.getContactPlanSubscriptions?.planTerm ?? ''}
                        </Text>
                        <Text size={500} marginBottom={8}>
                            <strong>Effective Dates: </strong>
                            {moment.utc(renewalSubData?.getContactPlanSubscriptions?.startDate).format('M/D/YYYY')} -{' '}
                            {moment.utc(renewalSubData?.getContactPlanSubscriptions?.endDate).format('M/D/YYYY')}
                        </Text>
                    </Pane>
                    <Pane display="flex" padding={16} justifyContent="flex-start">
                        <Button marginRight={8}
                            iconBefore={['fas', 'files-medical']}
                            onClick={() =>
                                showContractSheet({
                                    planId: renewalSubData?.getContactPlanSubscriptions?.plan?.id ?? '',
                                    planTerm: renewalSubData?.getContactPlanSubscriptions?.planTerm!,
                                    cancelled: renewalSubData?.getContactPlanSubscriptions?.isCancelled ?? false,
                                    effectiveDate: renewalSubData?.getContactPlanSubscriptions?.startDate,
                                    endDate: renewalSubData?.getContactPlanSubscriptions?.endDate
                                })
                            }
                        >
                            View Contract
                        </Button>
                        {renewalSubData?.getContactPlanSubscriptions?.isUpComing ?
                            <>
                                {(!isAdmin &&
                                    renewalSubData?.getContactPlanSubscriptions?.status === Types.PlanSubscriptionStatus.PROPOSED) ?
                                    (
                                        <Button marginRight={8}
                                            isLoading={updateSubscriptionStatus.loading}
                                            intent="success"
                                            onClick={() => acceptPlan(renewalSubData?.getContactPlanSubscriptions?.id ?? null)}
                                        >
                                            Accept
                                        </Button>
                                    ) : (<></>)
                                }
                                {(showCancelButton(renewalSubData?.getContactPlanSubscriptions)) ? (
                                    <Button marginLeft={0}
                                        onClick={() => setIsCancelDialogShown(true)}
                                    >
                                        Cancel
                                    </Button>
                                ) : (<></>)
                                }
                                {(showAuthorizedButton(renewalSubData?.getContactPlanSubscriptions)) ? (
                                    <>
                                    <Button marginLeft={0}
                                        onClick={() => setIsAuthorizedDialogShown(true)}
                                    >
                                        Authorized
                                    </Button>
                                     <Button marginLeft={0}
                                     onClick={() => setIsResendEmailDialogShown (true)}
                                 >
                                     Resend Email
                                 </Button>
                                 </>
                                ) : (<></>)
                                }
                            </> : <></>
                        }
                    </Pane>
                </>
            )
                :
                <>
                    {setIsRenewPlanShown
                        ?
                        <Button marginLeft={0}
                            onClick={() => setIsRenewPlanShown(true)}
                        >
                            Renew Plan
                        </Button>
                        :
                        <></>
                    }
                </>
        }
        </>)
}

export default CardUpcomingRenewal