import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { CREATE_USER, UPDATE_USER, DELETE_USER, GET_USERS, USER_SHEET_RESEND_INVITE } from 'graphql/_user-sheet'
import * as Types from 'types/graphql'

import { resetProviderPassword } from 'utility/auth'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { SideSheet, Pane, Paragraph, Avatar, Text, Label, Heading, toaster } from 'evergreen-ui'

import { Card, CardHeader, Button, FormError } from '@pearly/lib'
import UserFields from 'components/_fields/user-fields'
import { useModal } from 'components/modal-provider'
import { useGlobal } from 'components/global-provider'
import SystemUserFields from 'components/_fields/system-user-fields'
import { UserRole } from 'types'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  user?: {
    id: string
    firstName: string
    lastName: string
    google: { email: string; role: Types.UserRole; verified: boolean }
  }
}

const UserSheet = ({ isShown, setIsShown, user }: Props) => {
  const showConfirmDialog = useModal('confirm')
  const global = useGlobal()

  const isSystemAccount = global.account?.type === Types.AccountType.SYSTEM
 
  const [resendInvite, resendStatus] = useMutation<Types.UsersTableResendInvite>(USER_SHEET_RESEND_INVITE)
  const [resendLoading, setResendLoading] = useState(false)

  useEffect(() => {
    if (resendStatus.error) toaster.danger(`Unable to resend invitation`)
    else if (resendStatus.data && !resendStatus.loading) {
      toaster.success(`Invitation successfully resent`)
    }
  }, [resendStatus])

  const [createUser, createStatus] = useMutation<Types.CreateUser, Types.CreateUserVariables>(CREATE_USER, {
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetUsers>({ query: GET_USERS })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_USERS,
          data: { users: cachedData.users.concat([data.createUser]) }
        })
      }
    }
  })

  useEffect(() => {
    if (createStatus.error) {
      toaster.danger('Unable to create user')
      createStatus.error = undefined;
    }
    else if (createStatus.data && !createStatus.loading) {
      setIsShown(false)
      toaster.success(`User successfully created!`)
    }
  }, [createStatus, setIsShown])

  const [updateUser, updateStatus] = useMutation<Types.UpdateUser, Types.UpdateUserVariables>(UPDATE_USER)

  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update user')
    else if (updateStatus.data && !updateStatus.loading) {
      setIsShown(false)
      toaster.success(`User successfully updated!`)
    }
  }, [updateStatus, setIsShown])

  const [deleteUser, deleteStatus] = useMutation<Types.DeleteUser, Types.DeleteUserVariables>(DELETE_USER, {
    variables: { id: user?.id ?? '' },
    update: (cache, { data }) => {
      const cachedData = cache.readQuery<Types.GetUsers>({ query: GET_USERS })
      if (data && cachedData) {
        cache.writeQuery({
          query: GET_USERS,
          data: { users: cachedData.users.filter(user => user.id !== data.deleteUser.id) }
        })
      }
    }
  })

  useEffect(() => {
    if (deleteStatus.error) toaster.danger('Unable to delete user')
    if (deleteStatus.data && !deleteStatus.loading) {
      setIsShown(false)
      toaster.danger(`User successfully deleted!`)
    }
  }, [deleteStatus, setIsShown])

  return (
    <SideSheet isShown={isShown} onCloseComplete={() => setIsShown(false)} width={400} shouldCloseOnOverlayClick={false}>
      <Formik
        initialValues={
          user
            ? { ...user, email: user.google.email, role: user.google.role }
            : {
              firstName: '',
              lastName: '',
              email: '',
              role: isSystemAccount?Types.UserRole.SUPERADMIN:Types.UserRole.STANDARD
            }
        }
        onSubmit={({ ...userFields }) => {
          if (!createStatus.error) {
            showConfirmDialog({
              body: `Are you sure you want to ${user ? 'update' : 'create'} this user?`,
              confirm: () => {
                if (user) {
                  updateUser({
                    variables: { id: user.id, ...userFields }
                  })
                } else {
                  createUser({
                    variables: userFields
                  })
                }
              }
            })
          }
        }}
        validationSchema={Yup.object({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string()
            .required('Email is required')
            .email('Please enter a valid email'),
          role: Yup.mixed().oneOf(['ADMIN', 'STANDARD', 'SUPERADMIN'])
        })}
      >
        <Form style={{ height: '100%' }}>
          <SheetLayout>
            {user ? (
              <CardHeader gridArea="header">
                {/* Match color to table */}
                <Avatar name={`${user.firstName} ${user.lastName}`} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {user.firstName} {user.lastName}
                  </Heading>
                  <Text size={400}>User Details</Text>
                </Pane>
              </CardHeader>
            ) : (
                <CardHeader gridArea="header" flexDirection="column" alignItems="flex-start">
                  <Heading size={600}>Add User</Heading>
                  <Paragraph size={400}>This user will be granted access to the dashboard</Paragraph>
                </CardHeader>
              )}

            <Pane gridArea="body" overflow="scroll" background="blueTint">
              <Card backgroundColor="white" elevation={0} margin={16} padding={24}>
                { isSystemAccount? (<SystemUserFields/>): (<UserFields /> ) }
                {user && user.google.verified && (
                  <>
                    <Label marginTop={16} marginBottom={4}>
                      Password
                    </Label>
                    <Button
                      isLoading={resendLoading}
                      height={48}
                      iconBefore={['fas', 'paper-plane']}
                      onClick={() => {
                        showConfirmDialog({
                          body: `Are you sure you want to send a password reset?`,
                          confirm: () => {
                            setResendLoading(true)
                            resetProviderPassword(
                              user.google.email,
                              () => setResendLoading(false),
                              () => setResendLoading(false)
                            )
                          }
                        })
                      }}
                    >
                      Send Reset Password Email
                    </Button>
                  </>
                )}
                {user && !user.google.verified && (
                  <>
                    <Label marginTop={16} marginBottom={4}>
                      Invitation
                    </Label>
                    <Button
                      isLoading={resendStatus.loading && !resendStatus.data}
                      height={48}
                      iconBefore={['fas', 'paper-plane']}
                      onClick={() => resendInvite({ variables: { id: user.id } })}
                    >
                      Resend Invitation Email
                    </Button>
                  </>
                )}
                <FormError />
              </Card>
            </Pane>
            <Pane gridArea="footer" elevation={0} padding={16} textAlign="right">
              {user ? (
                <Pane display="flex" justifyContent="space-between">
                  {((global.meUser?.google.role !== UserRole.STANDARD && global.meUser?.google.role === UserRole.ADMIN && user.google.role !== UserRole.ADMIN) || (global.meUser?.google.role === UserRole.SUPERADMIN)) && <Button
                    isLoading={deleteStatus.loading || !!deleteStatus.data}
                    visibility={updateStatus.loading || updateStatus.data ? 'hidden' : 'visible'}
                    onClick={() => {
                      showConfirmDialog({
                        body: 'Are you sure you want to delete this user?',
                        confirm: () => {
                          deleteUser()
                        },
                        intent: 'danger'
                      })
                    }}
                    appearance="minimal"
                    intent="danger"
                    height={48}
                    justifyContent="center"
                  >
                    Delete
                  </Button>}
                  <Button
                    autoFocus
                    isLoading={updateStatus.loading || !!updateStatus.data}
                    visibility={deleteStatus.loading || deleteStatus.data ? 'hidden' : 'visible'}
                    type="submit"
                    appearance="primary"
                    height={48}
                    justifyContent="center"
                  >
                    Save
                  </Button>
                </Pane>
              ) : (
                  <Button
                    isLoading={createStatus.loading || !!createStatus.data}
                    type="submit"
                    appearance="primary"
                    height={48}
                    width="100%"
                    justifyContent="center"
                    iconBefore={['fas', 'user-plus']}
                  >
                    Add User
                  </Button>
                )}
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default UserSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
`
